import { get } from 'lodash';
import React from 'react';


const CalendarLineChart = (props) => {
  const { DailyCalendarMtd, DailyCount, Names } = props;

  const { week_name, month_name, data, week_start_from, } = DailyCalendarMtd;

  const defaultWeek = () => {
    for (let i = 0; i < week_start_from; i++) {
      return (
        <div key={i} style={{ backgroundColor: '#e6f0f1' }} className="col-md-1  text-center calendar-date-span ">
          <div><br />
            <span className="text-white">{''}</span>
          </div>
        </div>
      )
    }
  }

  return (

    <>
      <div className="row">
        <div style={{ background: '#2196f3', borderTopLeftRadius: 12, borderBottom: '5px solid darkblue', borderTopRightRadius: 12 }}
          className="col-lg-12 py-2">
          <p className=" px-2 py-2 text-white text-center mb-0"><strong>{Names} – Daily NPS | {month_name}</strong></p>

        </div>
      </div>
      <div className="row seven-cols  text-canter">

        {week_name && week_name.map((weekData, weekIndex) => (
          <div key={weekIndex} style={{ background: 'darkblue' }}
            className="col-md-1 text-white text-center border border-left-0 border-top-0 calendar-date-span ">
            <div className="">{weekData}</div></div>
        ))}

      </div>
      <div className="row seven-cols ">
        {defaultWeek()}
        {data && data.map((dateData, dateIndex) => (
          <div key={dateIndex} style={{ backgroundColor: '#e6f0f1' }}
            className="col-md-1 border border-left-0 position-relative border-top-0 text-center calendar-date-span ">

            <div>
              <div className="triangle-top text-white" >

              </div>
              <br />
              {dateData.date}
              <div className="triangle-right">

              </div>
              <div className="top-text">
                {dateData.nps_score === "" ? "-" : dateData.nps_score}
              </div>
              <div className="bottom-text">
                {dateData.totalSum === "" ? "-" : dateData.totalSum}
              </div>
            </div>
          </div>
        ))}

      </div>

    </>
  );
}

export default CalendarLineChart;
import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting';
// import exportData from 'highcharts/modules/export-data.js';
import heatmap from 'highcharts/modules/heatmap';


// heatmap(Highcharts);
exporting(Highcharts);
// exportData(Highcharts);



  const CustomCalendar = (props) => {
    
    const getPointCategoryName=(point, dimension)=> {
      var series = point.series,
          isY = dimension === 'y',
          axis = series[isY ? 'yAxis' : 'xAxis'];
      return axis.categories[point[isY ? 'y' : 'x']];
  }
    

const options = {
  chart: {
    type: 'heatmap',
    marginTop: 40,
    marginBottom: 80,
    plotBorderWidth: 1
},


title: {
    text: 'MTD NPS SCORE'
},

xAxis: {
    
    categories: ['Week-01', 'Week-02', 'Week-03', 'Week-04', 'Week-05']
},

yAxis: {
   
    categories: ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Satday'],
    title: null,
    reversed: true
},

accessibility: {
    point: {
        descriptionFormatter: function (point) {
            var ix = point.index + 1,
                xName = getPointCategoryName(point, 'x'),
                yName = getPointCategoryName(point, 'y'),
                val = point.value;
            return ix + '. ' + xName + ' Nps Score ' + yName + ', ' + val + '.';
        }
    }
},

colorAxis: {
    max:100,
    min:-100,
    minColor: '#DF5353',
    maxColor: '#DDDF0D',
  
},

legend: {
    align: 'right',
    layout: 'vertical',
    margin: 0,
    verticalAlign: 'top',
    y: 25,
    symbolHeight: 280
},

tooltip: {
    // formatter: function () {
    //     return '<b>' + getPointCategoryName(this.point, 'x') + '</b> Day <br><b>' +
    //         this.point.value + '</b> Nps score <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
    // }
    enabled: false
},
exporting: {
    enabled: false
  },
  credits: {
    enabled: false
},
series: [{
    colorByPoint: true,
    name: 'Daily Nps Score',
    borderWidth: 1,
    data: [
        [0, 1,19,'<br/> Feb-01  '],
      [0, 2, 'Feb-02 <br/> 0'],
      [0, 3, 'Feb-03 <br/> 19'],
      [0, 4,'Feb-04 <br/> 8'],
      [0, 5, 'Feb-05 <br/> 20'],
      [0, 6, 'Feb-06 <br/> -50'],
      [1,0,'Feb-07 <br/> 80'],
      [1,1,'Feb-08 <br/> 100'],
      [1, 2, 'Feb-07 <br/> 40'],
      [1, 1, 58],
      [1, 2, 78],
      [1, 3, -100],
      [1, 4, 48],
      [2, 1, 58],
      [2, 2, 78],
      [2, 3, 87], 
      [3, 2, -8],
      [3, 3, -24],
      [3, 4, 67],
      [4, 3, 100], 
      [4, 4,95], 
     
    ],
    dataLabels: {
        enabled: true,
        color: '#000000'
    }
}],

responsive: {
    rules: [{
        condition: {
            maxWidth: 500
        },
        chartOptions: {
            yAxis: {
                labels: {
                    formatter: function () {
                        return this.value.charAt(0);
                    }
                }
            }
        }
    }]
  }
}


return ( 
  <div className="container">
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
      
  />
  </div>
);
  
}

export default CustomCalendar;
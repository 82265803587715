import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Sankey from "highcharts/modules/sankey.js"
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash'
import CommentModel from '../../common/commentModel';
import { Loader } from '../../common/loader'

Sankey(Highcharts);
const CategoriesDepenciesChart = (props) => {

    const api = new API()

// CatSubCategories //
const [catSubCategoriesResultData, setCatSubCategoriesResultData] = useState({})
const [open,setOpen] = useState(false);
const [isLoad, setIsLoad] = useState(true);
const [nodeDataModel, setNodeDataModel] = useState([]);




useEffect(() => {
    catSubCategories()
  }, [])

const catSubCategories = async()=>{
    let catSubCategoriesResult = await api.get(config.dashboardCatSubCategories);
    let catSubCategoriesResultData = get(catSubCategoriesResult,'data',{})
    setCatSubCategoriesResultData(catSubCategoriesResultData)
    setIsLoad(false)
    // alert(catSubCategoriesResultData)
  }


const options = {
    chart: {
        height:550,
        width:1300,
    },

title: {
    text: ''
},

accessibility: {
    point: {
        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
    }
},
exporting: {
    enabled: false
  },
series: [{
    keys: ['from', 'to', 'weight'],
    data: catSubCategoriesResultData,
    events: {
        click:function (event) {
          handleModel(event)
          console.log(event,'event>>>>');
      }
    }
   
    ,type: 'sankey',
    name: ''
}]

}
const handleModel=async(e)=>{
    const { to,from } = get(e, 'point', 'options', {})
    const { isNull} = get(e, 'point', 'toNode', {})
    let data
 if (isNull === false) {
     data = {
        is_node: isNull,
        key: from,
        target_key: to,
    }
    
    await nodeModelDetail(data)
 } 
    
  }

  
  const nodeModelDetail = async (dataObj) => {
    let nodeModelDetail = await api.get(config.dashboardCatSubCategoriesDetails, true, dataObj);
    let nodeModelDetailResultData = get(nodeModelDetail, 'data', [])
    setNodeDataModel(nodeModelDetailResultData)
    if (nodeModelDetailResultData.length > 0) {
      setOpen(!open)
    }
  }
    return ( 
        <Fragment>
            { isLoad == true ? <> 
                    <Loader/>
                    </> : <> 

        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            catSubCategoriesResultData={catSubCategoriesResultData}
            
        />
        </>}
        <div style={{display:"none"}}>
            <CommentModel  handleModel={() => setOpen(!open)} commentDetailResultData={nodeDataModel} open={open}/>
        </div>
        </Fragment>
     );
}
 
export default CategoriesDepenciesChart;
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { withCookies } from 'react-cookie';
import { base_url } from '../../config/env';
import { Link } from 'react-router-dom';


const Voc = (props) => {
    
  const api = new API(props.cookies)
    const [logoRes,setLogoRes] = useState([]);
    const [trendChartRes,setTrendChartRes] = useState([]);
    const [loader, setLoader] = useState(false);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [mainCat, setMainCat] = useState('')
    const [surveyId, setSurveyId] = useState('')

 

    useEffect(() => {
        LogoResponse()
        // responseDownload(Id)
       
      }, [])

    const  LogoResponse =async()=>{
           
        let Data={}
            const result = await api.post(config.vocLogo, Data)
            let responseData = result && result.data
        
            setLogoRes(responseData)
            setLoader(false)
        
        
          }

//           const  TrendChartResponse =async()=>{
           
//             let Data={
//               survey_id: 85,
//               main_cat: mainCat,
//               start_date: startDate,
//               end_date : endDate,
//             }
//                 const result = await api.post(config.vocTrendChart, Data)
//                 let responseData = result && result.data
            
//                 setTrendChartRes(responseData)
//                 setLoader(false)
//              } 

// console.log()
    return ( 
        <>
        <div className="col-md-12 mt-3">
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
            <h5 className="">Voc  
          </h5>
        </div>
        <div className="card-body">
                        
            <div className="container">
            <div className="row">
                {logoRes && logoRes.map((data,index)=>(
                       <div key={index} className="col-md-3">
                              <Link className="text-white"
                          to={`/vocreport/${data.id}`} 
                        >
                       <img src={`${base_url}${data.logo}`} onClick={()=> setSurveyId(data.id)} className="refresh mb-2 mt-0" height={50} alt={data.logo}/>
                      
                       <h5 className="text-center mt-2">{data.title}</h5> <br/>
                       </Link>
                   </div>
                ))}
            </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        
        
        </>
     );
}
 
export default withCookies(Voc);
import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import WordCloud from "highcharts/modules/wordcloud.src.js"
import Pareto from "highcharts/modules/pareto.js"
import Exporting from "highcharts/modules/exporting.js"
import ExportData from "highcharts/modules/export-data.js"


ExportData(Highcharts);
Exporting(Highcharts);
WordCloud(Highcharts);
Pareto(Highcharts);

const DemoChart = (props) => {
  const { Name, DailyCount,MonthDate,MtdNpsScore,Count,NpsScore} = props


 
 
const options={
  title: {
    text: 'MTD Data Chart'
},



yAxis: {
  visible: false
},

xAxis: {
  type:'category',
  categories: MonthDate,//["Feb-01","Feb-02","Feb-03","Feb-04","Feb-05","Feb-06","Feb-07","MTD"],
    
},

legend: {
  enabled:false
},

// plotOptions: {
//     series: {
//         label: {
//             connectorAllowed: false
//         },
//         pointStart: 2010
//     }
// },
exporting: {
  enabled: false
},
series: [{
    name: 'Daily NPS Score',
    data: NpsScore,//[-45, 50, 15.23, 62, 0, 100, 80, 56]
    dataLabels: {
      enabled: true,
      rotation: 0,
      color: 'black',
      align: 'right',
       format: '{point.y:.1f}', // one decimal
      y: 5, // 10 pixels down from the top
      style: {
          fontSize: '8px',
          fontFamily: 'Verdana, sans-serif'
      },
  cursor: 'pointer'
    }
}],

responsive: {
    rules: [{
        condition: {
            maxWidth: 500
        },
        chartOptions: {
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            }
        }
    }]
}

}
  return (
    <Fragment>

      <HighchartsReact 
        highcharts={Highcharts}
        options={options}
      />


    </Fragment>
  );
}

export default DemoChart;
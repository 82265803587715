import React, { useEffect, useState,useRef,createRef } from 'react';
import OverallNpsScore from './common/OverallNpsScore';
import ProductUpiBasicDetail from './common/productUpiBasicDetail';
import RecentFeedbacksAndSuggestions from './common/RecentFeedbacksAndSuggestions';
import CategoriesDepenciesAndWordCloud from './common/CategoriesDependenciesAndWordCloud';
import ApplicationUsageComparision from './common/ApplicationUsageComparision';
import StateWiseImpactOnNps from './common/StateWiseImpactOnNps';
import SubNavBar from '../../common/subNavBar';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash'
import { dateFilter, lastMonth, currentMonth, currentDay } from '../../utils/common';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import moment from 'moment'
import { PDFExport } from '@progress/kendo-react-pdf';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';


const Dashboard = (props) => {

  const api = new API()
  const inputEl = useRef(null);

  const ref = createRef();
  // nps score //
  const [npci, setNpci] = useState([])
  // const [filterObj, setFilterObj] = useState({})
  const [filterObj, setFilterObj] = useState(getLocalDataAsObject('filter') || {})
  const [filterApply, setFilterApply] = useState(false)
  const [upi, setUpi] = useState([])
  const [npsArr, setNpsArr] = useState({})
  const [fastag, setFastag] = useState([])
  const [rupay, setRupay] = useState([])
  const [bhim, setBhim] = useState([])
  const [netc, setNetc] = useState([])
  const [loader, setLoader] = useState(true);
  const [skeletonLoader1, setSkeletonLoader1] = useState(true)
  
  const [upiShow,setUpiShow] = useState(false)
  const [rupayShow,setRupayShow] = useState(false)
  const [fastagShow,setFastagShow] = useState(false)
  const [bhimShow,setBhimShow] = useState(false)
  const [netcShow,setNetcShow] = useState(false)
  const [impsShow,setImpsShow] = useState(false)

  // basic Details //
  const [upiBasic, setUpiBasic] = useState({})
  const [fastagBasic, setFastagBasic] = useState({})
  const [rupayBasic, setRupayBasic] = useState({})
  const [bhimBasic, setBhimBasic] = useState({})
  const [impsBasic, setImpsBasic] = useState({})
  const [netcBasic, setNetcBasic] = useState({})

  // Application usages //
  const [applicationUsageResultData, setApplicationUsageResultData] = useState({})

// ROLE//
const [userRole,setUserRole] = useState({});

  useEffect(() => {
    if (getLocalDataAsObject('user')) {
            
      setUserRole(getLocalDataAsObject('user'))
      }
    let start_date = currentDay('end_date')
    let end_date = currentDay('end_date')
    npsScore({ start_date, end_date })
  },[])


  useEffect(() => {
    
    let start_date = currentDay('end_date')
    let end_date = currentDay('end_date')
    basicDetailUpi({ product: 1, start_date, end_date })
    basicDetailRupay({ product: 2, start_date, end_date })
    basicDetailFastag({ product: 3, start_date, end_date })
    basicDetailBhim({ product: 4, start_date, end_date })
    basicDetailNetc({ product: 5, start_date, end_date })
    basicDetailImps({ product: 6, start_date, end_date })
   
    
    applicationUsage()
  }, [])

 

  
  // nps score //

  const npsScore = async (dateFilter) => {
    setSkeletonLoader1(true)
    let npsScoreResult = await api.get(config.dashboardScore, true, dateFilter)
    let npci =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.npci
    let upi =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.upi
    let fastag =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.fastag
    let rupay =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.rupay
    let bhim =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.bhim
    let tempArrNps = npsScoreResult && npsScoreResult.data;
    delete tempArrNps.npci;
  
    setNpci(npci)
    setUpi(upi)
    setFastag(fastag)
    setRupay(rupay)
    setBhim(bhim)
    setNpsArr(tempArrNps)
    setSkeletonLoader1(false)
  }

const appliedFilterCallingApi=(filterObject={})=>{
  
  setFilterApply(prev =>!prev)
  let start_date = filterObject.start_date
  let end_date = filterObject.end_date
  start_date = start_date===undefined ?   currentDay('end_date') : start_date
  end_date = end_date===undefined ? currentDay('end_date') : end_date
  npsScore({ start_date, end_date })
  basicDetailUpi({ product: 1, start_date, end_date })
  basicDetailRupay({ product: 2, start_date, end_date })
  basicDetailFastag({ product: 3, start_date, end_date })
  basicDetailBhim({ product: 4, start_date, end_date })
  basicDetailNetc({ product: 5, start_date, end_date })
  basicDetailImps({ product: 6, start_date, end_date })

}


  // basic Details //
  const basicDetailUpi = async (dateFilter) => {
    let basicDetailUpiResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let upiBasic = basicDetailUpiResult && basicDetailUpiResult.data && basicDetailUpiResult.data.upi
    setUpiBasic(upiBasic)
  }

  const basicDetailRupay = async (dateFilter) => {
    let basicDetailRupayResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let rupayBasic = basicDetailRupayResult && basicDetailRupayResult.data && basicDetailRupayResult.data.rupay
    setRupayBasic(rupayBasic)
  }
  const basicDetailFastag = async (dateFilter) => {
    let basicDetailFastagResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let fastagBasic = basicDetailFastagResult && basicDetailFastagResult.data && basicDetailFastagResult.data.fastag
    setFastagBasic(fastagBasic)
  }
  const basicDetailBhim = async (dateFilter) => {
    let basicDetailBhimResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let bhimBasic = basicDetailBhimResult && basicDetailBhimResult.data && basicDetailBhimResult.data.bhim
    setBhimBasic(bhimBasic)
  }
  const basicDetailNetc = async (dateFilter) => {
    let basicDetailNetcResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let NetcBasic = basicDetailNetcResult && basicDetailNetcResult.data && basicDetailNetcResult.data.netc_webform
    setNetcBasic(NetcBasic)
  }
  const basicDetailImps = async (dateFilter) => {
    let basicDetailImpsResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let ImpsBasic = basicDetailImpsResult && basicDetailImpsResult.data && basicDetailImpsResult.data.imps
    setImpsBasic(ImpsBasic)
  }
  // Application usages //
  const applicationUsage = async () => {
    let applicationUsageResult = await api.get(config.dashboardGetDeviceAndTransaction);
    let applicationUsageResultData = applicationUsageResult.data
    setApplicationUsageResultData(applicationUsageResultData)
  }

  const onButtonPdfClick = () => {
    inputEl.current.save();
  };
  

  console.log("role==>",userRole)
  return (
    <div ref={ref} className="container-fluid" style={{ marginLeft: "2%" }}>
       <PDFExport
                    forcePageBreak=".page-break"
                    paperSize={'A1'}
                    margin="1.5cm"
                    scale={0.7}
                    fileName={ 'Dashboard.pdf'}
                    ref={inputEl}
                >
      <SubNavBar onButtonPdfClick={onButtonPdfClick} upiShow={upiShow} setUpiShow={setUpiShow} setRupayShow={setRupayShow} setFastagShow={setFastagShow} rupayShow={rupayShow} 
      fastagShow={fastagShow} filterApply={filterApply} setFilterApply={setFilterApply}  appliedFilterCallingApi={appliedFilterCallingApi} setFilterObj={setFilterObj} filterObj={filterObj}/>
 
      <OverallNpsScore UserRole={userRole} NpsArr={npsArr} loader={loader} npsScore={npsScore} Bhim={bhim} skeletonLoader1={skeletonLoader1} setSkeletonLoader1={setSkeletonLoader1}  Npci={npci} Upi={upi} Rupay={rupay} Fastag={fastag} />


      <ProductUpiBasicDetail upiShow={upiShow} setUpiShow={setUpiShow} setRupayShow={setRupayShow} setFastagShow={setFastagShow}
       rupayShow={rupayShow} fastagShow={fastagShow} bhimShow={bhimShow} setBhimShow={setBhimShow} netcShow={netcShow} setNetcShow={setNetcShow} impsShow={impsShow} setImpsShow={setImpsShow} 
       filterObj={filterObj} filterApply={filterApply} basicDetailUpi={basicDetailUpi} basicDetailRupay={basicDetailRupay} basicDetailFastag={basicDetailFastag} 
       basicDetailBhim={basicDetailBhim} basicDetailNetc={basicDetailNetc} basicDetailImps={basicDetailImps}  upiBasic={upiBasic} rupayBasic={rupayBasic} fastagBasic={fastagBasic} 
        bhimBasic={bhimBasic} impsBasic={impsBasic} />
      <RecentFeedbacksAndSuggestions />
      <CategoriesDepenciesAndWordCloud />
      <ApplicationUsageComparision applicationUsage={applicationUsageResultData} />
      <StateWiseImpactOnNps />
      </PDFExport>
    </div>
  );
}

export default Dashboard;
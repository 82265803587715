import React, { Fragment, useEffect, useState } from 'react';
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FB_APP_ID, GOOGLE_CLIENT_ID } from './social-key';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { validation } from '../common/configCommon';
import { setLocalDataAsObject } from '../utils/CoustomStorage';
import { toast } from 'react-toastify';

import { withCookies } from 'react-cookie';


const Login = ({cookies}) => {

  const api = new API()


  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
  })

  const onChangeInput = (e) => {
    const { value, name, checked } = e.target;
    // if (name === 'remember') {
    //   setLoginForm({
    //     ...loginForm,
    //     [name]: checked
    //   })
    // } else {
      setLoginForm({
        ...loginForm,
        [name]: value
      })
    // }
  }

  const loginSubmit = async () => {
    let result = await api.post(config.login, loginForm)
    if (result && result.code === 200) {
      await setLocalDataAsObject('user', result.data.user)
      await cookies.set('token', result.data.token)
      toast.info(result.message)
      // props.history.push('/dashboard')
    } 
    else {
      toast.error(result && result.message)
    }
    if (result && result.code === 401) {
      toast.error(result.message)
    }
  }



  const responseFacebook = async (response) => {
    console.log("triger facebook", response)
    // await console.log("response from facebook", response);
    // console.log("user data from facebook", response.email)
    // const facebookUserData = {
    //   email : response.email,
    //   firstName : response.name,
    //   fullName : response.name,
    //   profileImage : response.picture.data.url
    // }
    // console.log("user data is", facebookUserData)
    // setRegister({...registerForm, 
    //   email : facebookUserData.email,
    //   firstName : facebookUserData.firstName,
    //   fullName : facebookUserData.fullName,
    //   profileImage : facebookUserData.profileImage
    //   })
  }

  const responseGoogle = async (response) => {
    await console.log("respone from google", response);

    //     const GoogleUserData = {
    //       email : response.profileObj.email,
    //       firstName : response.profileObj.givenName,
    //       lastName : response.profileObj.familyName,
    //       fullName : response.profileObj.name,
    //       profileImage : response.profileObj.imageUrl
    //     }
    //     console.log("user data is", GoogleUserData)
    //     setRegister({...registerForm, 
    //       email : GoogleUserData.email,
    //       firstName : GoogleUserData.firstName,
    //       lastName : GoogleUserData.lastName,
    //       fullName : GoogleUserData.fullName,
    //       profileImage : GoogleUserData.profileImage
    //       })
  }

  
  return (
    <Fragment>
      <div className="login">
      <div className="overlay"></div>
      <div className="container container1">
      <div className="row">
        <div className="col-lg-7"> 
        <img src="img/banner-1.png"  className="mx-auto d-block mb-4" />
        </div>
        <div className="col-lg-5">
        <div className="wrap formquick">
          <div className="logo">
          {/* <h2>SurveyCRM</h2> */}
            <img src="img/logocrm.png"  className="mx-auto d-block" />
          </div>
          <AvForm onValidSubmit={loginSubmit} className="mt-5">
            <div className="form-group">
              {/* <img src="img/user-name.png"  className="mx-auto d-block" /> */}
              <AvField type="text" name="username" value={loginForm.username} className="form-control  py-4" 
              onChange={onChangeInput} placeholder="Enter email" 
              validate={{
                required: {
                  value: true,
                  errorMessage: "Email is required",
                }
              }}
              />
            </div>
            <div className="form-group">
              
            {/* <img src="img/key-password.png"  className="mx-auto d-block" /> */}
              <AvField type="password" name="password" value={loginForm.password} onChange={onChangeInput}
                className="form-control py-4" placeholder="Enter password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Password is required",
                  }
                }}
              />
              {/* <input type="password" className="form-control py-3 rounded-0" placeholder="Enter password" id="pwd" /> */}
            </div>
            <div className="form-group form-check">
              {/* <label className="form-check-label">
                <input className="form-check-input" type="checkbox" /> Remember me
        </label> */}
              {/* <span className="float-right"><a href="#">Forget Password?</a></span> */}
            </div>
            <button type="submit" className="btn btn-primary-fb bg-primary btn-block rounded-0 py-2 mb-4">LOG IN</button>
            {/* <p className="text-center">Use single sign-on authentication</p> */}
            {/* <div className="row">
              <div className=" col-lg-6"> 
              <GoogleLogin
                  className="btn gbtn btn-block"
                  clientId={GOOGLE_CLIENT_ID}
                  icon={false}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                >
                  <i className="fa fa-google new text-danger  " aria-hidden="true" />  Google
                    </GoogleLogin>
              </div>
              <div className=" col-lg-6">
                 <FacebookLogin
                  type="button"
                  textButton=" Facebook"
                  fields="name,email,picture"
                  cssClass="btn btn-primary-fb btn-block"
                  appId={FB_APP_ID}
                  autoLoad={false}
                  icon="fa-facebook text-primary new"
                  callback={responseFacebook}
                >  </FacebookLogin>
              </div>
            </div>
            <hr /> */}
            {/* <p className="text-center "><a href="#">Create A New Account ?</a></p> */}
          </AvForm>
        </div>
        </div>
        </div>
     </div>
      </div>

    </Fragment>
  );
}

export default withCookies(Login);
import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
// import Pagination from "react-js-pagination";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { CSVLink, CSVDownload } from "react-csv";
import Pdf from "react-to-pdf";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import _ from "lodash";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PDFExport } from '@progress/kendo-react-pdf';
import { base_url } from '../config/env';
import { saveAs } from 'file-saver';
import DateRangePickerDump from './dateRangePickerDump';
var FileSaver = require('file-saver');



const DailyUpdateNpsDataTable = (props) => {
  const csvLink = useRef()
  const inputEl = useRef(null);
  const ref = React.createRef();
  const api = new API()
  const {Id} = props;
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [surveyResponseList, setSurveyResponseList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)
  const [responseDownloadData, setResponseDownloadData] = useState([])
  const [itemPerPage, setItemPerPage] = useState(5)
  const [searchMobile, setSearchMobile] = useState('')
  const [searchPartner, setSearchPartner] = useState('')
  const [sendStatus, setSendStatus] = useState('')
  const [surveyResponse, setSurveyResponse] = useState('')
  const [isAsending, setIsAsending] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [msg, setMsg] = useState('')
  const [productName, setProductName] = useState('')
  const [more, setMore] = useState(false);
  const [downloadDump, setDownloadDump] = useState(false);
  const [disable,setDisable] = useState(true)
  const [url,setUrl] = useState(null)
  const [download,setDownload] = useState({})

  useEffect(() => {
    dumpListDataResponse()
    // responseDownload(Id)
   
  }, [])

  useEffect(() => {
    dumpListDataResponse()
    // responseDownload(Id)
  }, [page, itemPerPage, sendStatus, surveyResponse, searchPartner, searchMobile])


  const dumpListDataResponse = async () => {
    let
      Data = {
        page_no: page, survey_id: Id, item_per_page: itemPerPage,
        send_status: sendStatus, survey_response: surveyResponse, mobile: searchMobile, partner: searchPartner,
        start_date: startDate, end_date: endDate
      }
    if (Data.mobile === "") {
      delete Data.mobile
    } 
    if (Data.survey_id === "" || Data.survey_id === undefined) {
      delete Data.survey_id
    }
    if (Data.send_status === "") {
      delete Data.send_status
    }
    if (Data.survey_response === "") {
      delete Data.survey_response
    }
    if (Data.partner === "") {
      delete Data.partner
    }
    if (Data.start_date === "") {
      delete Data.start_date
    }
    if (Data.end_date === "") {
      delete Data.end_date
    }

    const result = await api.post(config.dailyNpsUpdateListResponseDownload, Data)
   if (result.error === false) {
    let responseData = result && result.data && result.data.result
    let surveyResponseData = result && result.data

    setSurveyResponseList(responseData)
    setTotalItem(surveyResponseData && surveyResponseData.total_item)
    setTotalPage(surveyResponseData && surveyResponseData.total_page)
    setTotalData(surveyResponseData && surveyResponseData.last_index)
    setCurrentDataCount(surveyResponseData && surveyResponseData.current_page)
    setFirstIndexItem(surveyResponseData && surveyResponseData.first_index)
   }
    setLoader(false)


  }

  const handlePageChange = async (event, value) => {
    setPage(value);
    setLoader(false)
  }



  const responseDownload = async (Id,done=null) => {

   
    let Datas = {
      survey_id: Id, send_status: sendStatus, survey_response: surveyResponse, mobile: searchMobile, partner: searchPartner,
      start_date: startDate, end_date: endDate
    }
    if (Datas.mobile === "") {
      delete Datas.mobile
    }
    if (Datas.send_status === "") {
      delete Datas.send_status
    }
    if (Datas.survey_response === "") {
      delete Datas.survey_response
    }
    if (Datas.partner === "") {
      delete Datas.partner
    }
    if (Datas.start_date === "") {
      delete Datas.start_date
    }
    if (Datas.end_date === "") {
      delete Datas.end_date
    }
    const result = await api.post(config.reportSurveyResponseDownload, Datas)
    setResponseDownloadData(result.data)
    done && done()
    console.log(result);
    setLoader(false)
    setDisable(false)
  }

  const sortFilter = (sortBy, order) => {
    let tem = _.orderBy(surveyResponseList, [sortBy], [order])
    console.log(tem, "tem");
    setSurveyResponseList(tem);
    setIsAsending(prev => !prev)
    setLoader(false)
  };
  const onButtonPdfClick = () => {
    inputEl.current.save();
  };

  const downloadDumpDate =async()=>{
    let obj = {
      start_date: startDate, end_date: endDate,survey_id: Id,
    }
    // if (obj.start_date === "") {
    //   delete obj.start_date
    // }
    // if (obj.end_date === "") {
    //   delete obj.end_date
    // }

   if (startDate !== "" && endDate !== "") {
    const result = await api.post(config.dumpListDateRangeData, obj)
    if (result && result.data && result.data.is_created === 1) {
     setUrl('Created')
     setMsg(result.message)
     setProductName(result.data.prd_name)
     setDownloadDump(true)
     dumpListDataResponse()
    }
   }
    setLoader(false)
    setDisable(false)
  }

const downloadDumpResponseSet =()=>{
  if (downloadDump === true) {
    
  dumpListDataResponse()
  }
}


console.log("==>>",Id)
  return (

    <div className="container">
      <div className="bg-dark card-body py-3  text-center"><h5 className="mb-0 text-white">OVERALL DAILY NPS DUMP DATA LIST</h5>
      </div>
      {loader == true ?
        <Skeleton count={20} /> :
        <>
          <div className="text-left py-4 d-flex bg-light card-body justify-content-between">
            {/* <div className>
              <label for="email">Rows per page:</label>
              <select onChange={(e) => setItemPerPage(e.target.value)} value={itemPerPage} className="form-control" id="sel1">
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div> */}

            {/* <DateRangePickerDump DateStart={setStartDate} DateEnd={setEndDate} /> */}
             {/* <button className="btn btn-info rounded-1 float-right font-weight-normal  mt-3" onClick={downloadDumpDate}>Generate</button> */}
            {/* {url && <Button onClick={() => FileSaver.saveAs(`${base_url}${data.filename}`, `${base_url}${data.filename}`)}className="text-info read-date" >Download</Button>} */}
            {/* <i onClick={dumpListDataResponse} className="fa fa-refresh text-info float-right btn cursor mt-4" title="Refresh"/> */}
         
     
            {/* <ButtonDropdown  isOpen={more} toggle={() => setMore(!more)}>
              <DropdownToggle className="btn white-btn mt-4">
                <i    className="fa  fa-bars ml-2 btn color-white" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                </DropdownItem>

                <DropdownItem>
                  <span onClick={onButtonPdfClick}>Generate Pdf</span>

                </DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown> */}

          </div>
          <PDFExport
            forcePageBreak=".page-break"
            paperSize={'A4'}
            margin="1.5cm"
            scale={0.7}
            fileName={'Survey-Response-List.pdf'}
            ref={inputEl}
          >
            <div ref={ref} className="table text-center mb-4">

             

               { surveyResponseList.length > 0 &&
               <>
                <table className="table table-Common table-bordered table-striped" >


                <thead>
                  <tr className="bg-secondary text-white">
                    <th>S No.</th>
                    <th style={{ cursor: 'pointer' }} >Type</th>
                    <th style={{ cursor: 'pointer' }} >Date</th>
                    <th style={{ cursor: 'pointer' }} >Status</th>
                    <th style={{ cursor: 'pointer' }} >Action</th>
                     </tr>
                </thead>
                <tbody>
                

                  {surveyResponseList && surveyResponseList.map((data, index) => (
                    <tr key={index}>
                      <td className="table-td-1" scope="row">{firstIndexItem + index}</td>
                      <td >Daily Overall</td>
                      <td >{data.start_date} - {data.end_date} </td>
                      <td >{data.status=== 0 ? "Pending" :"Received"}</td>
                      <td className="read-date" onClick={() => FileSaver.saveAs(`${base_url}${data.filename}`, `${base_url}${data.filename}`)}>{data.status=== 0 ? "--" : "Download"}</td>
                      
                    </tr>
                  ))}
                </tbody> 
                </table>
                <div className="text-center d-flex justify-content-between align-items-center"  >
                <Pagination count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="primary" align="center" />
                <h5 className="float-left">{firstIndexItem} - {totalData} of {totalItem} items</h5>
              </div>
              </>

}
              { surveyResponseList.length === 0 && 
              <>
               <table className="table table-Common table-bordered table-striped" >


               <thead>
                 <tr className="bg-secondary text-white">
                   <th>S No.</th>
                   <th style={{ cursor: 'pointer' }} >Product Name</th>
                   <th style={{ cursor: 'pointer' }} >Date</th>
                   <th style={{ cursor: 'pointer' }} >Status</th>
                   <th style={{ cursor: 'pointer' }} >Action</th>
                    </tr>
               </thead>
              <tbody>
                  
                <tr>
                      <td className="table-td-1" scope="row">1</td>
                      <td >{productName}</td>
                      <td >{startDate} - {endDate} </td>
                      <td >Pending</td>
                      <td className="read-date" onClick={downloadDumpResponseSet}>{msg}</td>
                      
                    </tr>
                  
                    </tbody>
                    
              </table>
              <div className="text-center d-flex justify-content-between align-items-center "  >
                <Pagination count={totalPage} page={page} onChange={handlePageChange} className="mb-4" variant="outlined" color="primary" align="center" />
                <h5 className="float-left mb-4">{firstIndexItem} - {totalData} of {totalItem} items</h5>
              </div>
              </>
                    }





            </div>
          </PDFExport>
        </>
      }
    </div >
  );
}

export default DailyUpdateNpsDataTable;
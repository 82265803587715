import React, { useState, useEffect } from 'react';


const SentimentSmile = (props) => {
const {SentimentData} = props;

    return ( 
        <div className="container py-5">
            
  {/* Control the column width, and how they should appear on different devices */}
  <div className="row">
  <div className="col-md-12"> 
  <div className="bg-dark card-body py-3  text-center"><h5 className="mb-0 text-white">VOC Sentiment </h5></div>
  <div className="row mt-4">
  {SentimentData && SentimentData.length === 0 ? 
    
                      <h4 className="text-center">"No Result"</h4>
                    
                       :
      SentimentData && SentimentData.map((data,index)=>(
          <div key={index} className="col-sm-4">
          <img src={ data.sentiment === "Negative" ? "/img/passive.png" : null || data.sentiment === "Neutral" ? "/img/detectors.png" : null || data.sentiment === "Positive" ? "/img/promoter.png" : null } 
          className="mx-auto d-block px-0 mb-4" height={100} alt="" />
          <div className={ data.sentiment === "Negative" ? "card bg-danger" : null || data.sentiment === "Neutral" ? "card bg-warning" : null || data.sentiment === "Positive" ? "card bg-success" : null }>
            <div className="card-body text-center">
              <h1 className="text-white">{data.sentiment}</h1>
              <h2 className="text-white">NPS :- {data.nps_score}%</h2>
              <h2 className="text-white">Total Response :- {data.total_response}</h2>
              <h4 className="text-white">Promotor :- {data.promoters}</h4>
              <h4 className="text-white">Passive :- {data.passive}</h4>
              <h4 className="text-white">Detractor :- {data.detractors}</h4>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div></div>


     );
}
 
export default SentimentSmile;
import React, { useEffect, useState, useRef, createRef } from 'react';
import { get } from 'lodash';
import { PDFExport } from '@progress/kendo-react-pdf';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { exportComponentAsJPEG, exportComponentAsPNG } from 'react-component-export-image';
import { dateFilter, lastMonth, currentMonth, currentDay, currentMonthDayMinusOne } from '../../utils/common';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import './npsUpdateForBuds.css';
import NpsBudsReport from './NpsBudsReport';



const NpsUpdateForBuds = () => {
    const api = new API()
    const inputEl = useRef(null);
    const ref = createRef();
    const [more, setMore] = useState(false);

    // nps score //
    const [npsArr, setNpsArr] = useState({})

    // ROLE//
    const [userRole, setUserRole] = useState({});

    const onButtonPdfClick = () => {
        inputEl.current.save();
    };


    const npsScore = async (dateFilter) => {
        let npsScoreResult = await api.get(config.dashboardScore, true, dateFilter)
        let tempArrNps = npsScoreResult && npsScoreResult.data;
        delete tempArrNps.npci;

        setNpsArr(tempArrNps)
    }

    useEffect(() => {
        if (getLocalDataAsObject('user')) {

            setUserRole(getLocalDataAsObject('user'))
        }
        let start_date = currentMonth('start_date')
        let end_date = currentMonthDayMinusOne('end_date')
        npsScore({ start_date, end_date })
    }, [])

    const percentageCalculation = (Obj, key) => {
        let calculateValue = ((get(Obj, key, 0)) / ((get(Obj, 'total_promoters_rating', 0)) + (get(Obj, 'total_passive_rating', 0)) + (get(Obj, 'total_detectors_rating', 0))) * 100)

        calculateValue = isNaN(calculateValue) ? 0 : calculateValue
        return calculateValue.toFixed(2)
    }



    return (
        <>
        <div className="container-fluid justify-content-center d-flex mt-2">
            <PDFExport
                forcePageBreak=".page-break"
                paperSize={'A1'}
                // margin="1.5cm"
                // scale={0.7}
                fileName={'overall-nps-buads-report.pdf'}
                ref={inputEl}
            >
                {/* Dropdown header start*/}
                <div className="card">
                    <div className="p-1 ">
                        <div className="card-header mtd-header mt-3">
                            <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between">
                                <div className="d-block  mx-auto text-center">
                                    <label className="text-white f-24">NPCI SERVICES-MTD</label>
                                </div>
                                <ButtonDropdown isOpen={more} toggle={() => setMore(!more)}>
                                    <DropdownToggle className="btn white-btn1">
                                        <i className="fa  fa-bars ml-2 btn color-white" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            {/* <CSVLink color="black" data={responseDownloadData}
                        filename={"Mtd-report.csv"} ref={csvLink}
                    >Excel Download
                </CSVLink> */}
                                        </DropdownItem>
                                        <DropdownItem>
                                            <span onClick={onButtonPdfClick}>Generate Pdf</span>

                                        </DropdownItem>
                                        <DropdownItem filename={"Mtd-report.jpg"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                                        <DropdownItem filename={"Mtd-report.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </h5>

                        </div>
                    </div>


                    {/* Report start */}
                    <div ref={ref} className="p-2 mb-5">


                        <div className="row">

                            {/* BHIM */}
                            <div className="col">
                                <div className="box">
                                    <div className="our-services settings">
                                        <div className="icon">
                                            <img src="/img/bhim-color.png" width="200px" />
                                        </div>
                                        <strong>NPS : {Math.round(get(npsArr["bhim_new_survey_v2"], 'total_score', '-100'))}</strong>
                                        <hr className="text-center" />
                                        <div className="main-div">

                                            <div className="text-img">
                                                <img className="img" src="/img/npci-arrow-report.png" alt="#" />
                                            </div>
                                            <div className="text-1">
                                                <p className="mb-0" >{(get(npsArr["bhim_new_survey_v2"], 'total_response', 0))}</p>
                                                <p className="mb-0">Responses</p>
                                            </div>
                                            <div className="text-2">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["bhim_new_survey_v2"], 'total_promoters_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Promoters</p>
                                            </div>
                                            <div className="text-3">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["bhim_new_survey_v2"], 'total_passive_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Passive</p>
                                            </div>
                                            <div className="text-4">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["bhim_new_survey_v2"], 'total_detectors_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Detractors</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>



                            {/*Rupay*/}
                            <div className="col">
                                <div className="box">
                                    <div className="our-services settings">
                                        <div className="icon">
                                            <img src="/img/rupay.png" width="200px" />
                                        </div>
                                        <strong>NPS : {Math.round(get(npsArr["rupay"], 'total_score', '-100'))}</strong>
                                        <hr className="text-center" />
                                        <div className="main-div">

                                            <div className="text-img">
                                                <img className="img" src="/img/npci-arrow-report.png" alt="#" />
                                            </div>
                                            <div className="text-1">
                                                <p className="mb-0" >{(get(npsArr["rupay"], 'total_response', 0))}</p>
                                                <p className="mb-0">Responses</p>
                                            </div>
                                            <div className="text-2">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["rupay"], 'total_promoters_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Promoters</p>
                                            </div>
                                            <div className="text-3">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["rupay"], 'total_passive_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Passive</p>
                                            </div>
                                            <div className="text-4">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["rupay"], 'total_detectors_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Detractors</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* UPI */}
                            <div className="col">
                                <div className="box">
                                    <div className="our-services settings">
                                        <div className="icon">
                                            <img src="/img/upi.png" width="200px" />
                                        </div>
                                        <strong>NPS : {Math.round(get(npsArr["upi_new_survey"], 'total_score', '-100'))}</strong>
                                        <hr className="text-center" />
                                        <div className="main-div">

                                            <div className="text-img">
                                                <img className="img" src="/img/npci-arrow-report.png" alt="#" />
                                            </div>
                                            <div className="text-1">
                                                <p className="mb-0" >{(get(npsArr["upi_new_survey"], 'total_response', 0))}</p>
                                                <p className="mb-0">Responses</p>
                                            </div>
                                            <div className="text-2">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["upi_new_survey"], 'total_promoters_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Promoters</p>
                                            </div>
                                            <div className="text-3">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["upi_new_survey"], 'total_passive_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Passive</p>
                                            </div>
                                            <div className="text-4">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["upi_new_survey"], 'total_detectors_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Detractors</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>



                            {/* Fastag */}
                            <div className="col">
                                <div className="box">
                                    <div className="our-services settings">
                                        <div className="icon">
                                            <img src="/img/fastag.png" width="200px" />
                                        </div>
                                        <strong>NPS : {Math.round(get(npsArr["fastag_survey_new"], 'total_score', '-100'))}</strong>
                                        <hr className="text-center" />
                                        <div className="main-div">

                                            <div className="text-img">
                                                <img className="img" src="/img/npci-arrow-report.png" alt="#" />
                                            </div>
                                            <div className="text-1">
                                                <p className="mb-0" >{(get(npsArr["fastag_survey_new"], 'total_response', 0))}</p>
                                                <p className="mb-0">Responses</p>
                                            </div>
                                            <div className="text-2">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["fastag_survey_new"], 'total_promoters_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Promoters</p>
                                            </div>
                                            <div className="text-3">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["fastag_survey_new"], 'total_passive_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Passive</p>
                                            </div>
                                            <div className="text-4">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["fastag_survey_new"], 'total_detectors_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Detractors</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* IMPS */}
                            <div className="col">
                                <div className="box">
                                    <div className="our-services settings">
                                        <div className="icon">
                                            <img src="/img/imps-fastag.png" width="200px" />
                                        </div>
                                        <strong>NPS : {Math.round(get(npsArr["imps"], 'total_score', '-100'))}</strong>
                                        <hr className="text-center" />
                                        <div className="main-div">

                                            <div className="text-img">
                                                <img className="img" src="/img/npci-arrow-report.png" alt="#" />
                                            </div>
                                            <div className="text-1">
                                                <p className="mb-0" >{(get(npsArr["imps"], 'total_response', 0))}</p>
                                                <p className="mb-0">Responses</p>
                                            </div>
                                            <div className="text-2">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["imps"], 'total_promoters_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Promoters</p>
                                            </div>
                                            <div className="text-3">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["imps"], 'total_passive_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Passive</p>
                                            </div>
                                            <div className="text-4">
                                                <p className="mb-0" >{`${Math.round(percentageCalculation(npsArr["imps"], 'total_detectors_rating'))}${'%'}`}</p>
                                                <p className="mb-0">Detractors</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    {/* Report end */}

                    {/* <p className='note'>**BHIM - 11<sup>th</sup>Dec till 31<sup>th</sup>Jan | RuPay - 31<sup>th</sup>Dec till 31<sup>th</sup>Jan | UPI - 28<sup>th</sup>Dec till 31<sup>th</sup>Jan | NETC - 28<sup>th</sup>Jan till 31<sup>th</sup>Jan</p><p className='note'> Started on 28<sup>th</sup>Dec till 31<sup>th</sup>Jan</p> */}




                    {/* <div className="row">
                <div className="col-lg-12">
                    
                    <CalendarLineChart Names={"UPI"} DailyCalendarMtd={upiDailyCalendarMtd} />
                </div>

                <div className="col-lg-12">

                    {upiDailyMtd.map((mtdData, mtdIndex) => (

                    <DemoChart
                        DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])}
                    />
                    ))}
                </div>

                </div> */}
                </div>

            </PDFExport>



        </div>
        <NpsBudsReport/>
        </>
    )
};

export default NpsUpdateForBuds;

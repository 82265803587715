import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;
const TableZone = (props) => {
  const{zoneArr,onChange,selectedZone} = props;
const[show,setShow] = useState(false)
   

const handleChange = (value) => {
  onChange(value,'zone')
}
    
   

   


    return ( 
     
          <div className="col-lg-3 mt-4 px-1 ">
          <Select value={selectedZone} placeholder={'select zone'} mode="multiple" onChange={handleChange} style={{width:'100%'}}>
          {zoneArr.map((data,index) => (
            <Option key={index} value={data.zone}>{data.zone}</Option>
          ))}
          </Select>
          </div>
     
    
     );
}
 
export default TableZone;
import Config from '../config';

export const config ={
    dashboardScore : `${Config.site_api}/dashboard/score`,
    dashboardBasicDetails : `${Config.site_api}/dashboard/basicDetail`,
    dashboardGetDeviceAndTransaction : `${Config.site_api}/dashboard/getDeviceAndTransactionScore`,
    dashboardCatSubCategories : `${Config.site_api}/dashboard/catSubCategories`,
    dashboardCatSubCategoriesDetails : `${Config.site_api}/dashboard/catSubCategoriesDetail`,
    
    dashboardAppUseComparision : `${Config.site_api}/dashboard/appUseComparision`,
    // dashboardQuestionAndResponse : `${Config.site_api}/dashboard/questionAndResponse`,
    dashboardQuestionAndResponseUpi : `${Config.site_api}/dashboard/questionAndResponse?product_id=1`,
    dashboardQuestionAndResponseRupay : `${Config.site_api}/dashboard/questionAndResponse?product_id=2`,
    dashboardQuestionAndResponseFastag : `${Config.site_api}/dashboard/questionAndResponse?product_id=3`,
    dashboardQuestionAndResponseBhim : `${Config.site_api}/dashboard/questionAndResponse?product_id=4`,
    dashboardQuestionAndResponseNetc : `${Config.site_api}/dashboard/questionAndResponse?product_id=5`,
    dashboardQuestionAndResponseImps : `${Config.site_api}/dashboard/questionAndResponse?product_id=6`,
    dashboardWordCloud: `${Config.site_api}/dashboard/wordCloud`,
    dashboardStateWiseResponse: `${Config.site_api}/dashboard/stateWiseResponse`,
    dashboardStateWiseMapResponse: `${Config.site_api}/dashboard/stateWiseMapResponse`,
    dashboardRatingWiseNpsScore: `${Config.site_api}/dashboard/ratingWiseNpsScore`,
    dashboardRatingWisePieNpsScore: `${Config.site_api}/dashboard/ratingWiseNpsScorePieChart`,
    dashboardQuestionAndOptions: `${Config.site_api}/dashboard/questionAndOptions`,
    dashboardQDailyNpsTrend: `${Config.site_api}/dashboard/npsTrend`,
    dashboardRatingWiseComment: `${Config.site_api}/dashboard/ratingWiseComment`,
    dashboardDppDataUpi : `${Config.site_api}/dashboard/questionDPP?product_id=1`,
    dashboardDppDataRupay : `${Config.site_api}/dashboard/questionDPP?product_id=2`,
    dashboardDppDataFastag : `${Config.site_api}/dashboard/questionDPP?product_id=3`,
    dashboardDppDataBhim : `${Config.site_api}/dashboard/questionDPP?product_id=4`,
    dashboardDppDataNetc : `${Config.site_api}/dashboard/questionDPP?product_id=5`,
    dashboardDppDataImps : `${Config.site_api}/dashboard/questionDPP?product_id=6`,
    dashboardPartnerWiseResponse : `${Config.site_api}/dashboard/partnerWiseNpsScore`,
    dashboardCommentDetailClicked : `${Config.site_api}/dashboard/commentDetail`,
    dashboardCommentDetail : `${Config.site_api}/dashboard/wordCloudCommentDetail`,
    dashboardKeyParameters : `${Config.site_api}/dashboard/keyParameters`,//http://localhost:3002/dashboard/commentDetail?type=Passive&comment=Test
    dashboardFeedbackAndSuggestion: `${Config.site_api}/dashboard/feedbackAndSuggetion`,
    dashboardShowAllComments: `${Config.site_api}/dashboard/allFeedbackAndSuggetion`,
    dashboardAndroidIos: `${Config.site_api}/dashboard/getDeviceScore`,
    login: `${Config.site_api}/auth/admin/local/`,
    reportSurveyList: `${Config.site_api}/survey/list`,
    reportPartnerList: `${Config.site_api}/partner/list`,
    reportPartnerStateWiseList: `${Config.site_api}/partner/get-statewise-nps-list`,
    reportPartnerCityWiseList: `${Config.site_api}/partner/get-citywise-nps-list`,
    getZone: `${Config.site_api}/survey/zone-list`,
    getState: `${Config.site_api}/survey/state-list`,
    getCity: `${Config.site_api}/survey/city-list`,
    downloadCityWiseReport: `${Config.site_api}/partner/citywise-nps-download`,
    reportPartnerDownload: `${Config.site_api}/partner/download`,
    dumpListData: `${Config.site_api}/common/dump-list`,
    dumpListDateRangeData: `${Config.site_api}/dump/create-date_range-dump`,
    reportSurveyResponseList: `${Config.site_api}/survey/response/list`,
    vocReportList: `${Config.site_api}/survey/voc-list`,
    reportSurveyResponseDownload: `${Config.site_api}/survey/response/download`,
    DashboardProductMtd: `${Config.site_api}/dashboard/productMTD`,
    ReportPartnerListCommon: `${Config.site_api}/partner/list-common`,
    ReportPartnerDailyMtdCommon: `${Config.site_api}/partner/daily-mtd`, 
    ReportDailyMtdCalendar: `${Config.site_api}/partner/daily-mtd-calendar`,
    statewiseNpsResponseDownload: `${Config.site_api}/partner/statewise-nps-download`,
    dailyNpsUpdateListResponseDownload: `${Config.site_api}/dump/daily-nps-update-list`,
    vocLogo: `${Config.site_api}/survey/log-list`,
    vocTrendChart: `${Config.site_api}/survey/voc-chart-data`,
    vocSentimentNps: `${Config.site_api}/survey/sentiment-nps`,
    quadrantChart: `${Config.site_api}/survey/quadrant-chart-data`,

    // dashboardfeedbackAndSuggetion: `${Config.site_api}/dashboard/wordCloudCommentDetail`,

}


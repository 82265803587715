import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting';
import heatmap from 'highcharts/modules/heatmap';
import ReactDOMServer from 'react-dom/server';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash';
import { parse } from 'date-fns';


heatmap(Highcharts);
exporting(Highcharts);

const CustomTooltip = (props) => {
    const {quadrantDataCounts,point} = props;

    const {dataModal} = point;
    console.log("props",props,point && point.options)
    return (
        <div className="card" style={{ height: "300px !important", width: "350px !important" }}>
            <div className="card-head p-0 m-2">
                <h6>{point && point.options && point.options.name}</h6>
            </div>
            <div className="card-body">
                <div className="row" >
                    <div className="col-md-5">
                        <div style={{ width: 100, height: 100 }} className="text-center">
                            <CircularProgressbarWithChildren value={point && point.options && point.options.nps_score}>
                                {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

                                {point && point.options && parseFloat(point.options.nps_score).toFixed(2)}
                            </CircularProgressbarWithChildren>
                            <div className="mt-1">NPS Score</div>
                        </div>
                    </div>
                    <div className="col-md-7" >
                        <div className="row" >
                            <div className="col-md-12 m-2 p-1" >
                                <img src="/img/promoter.png" height={30} alt="img" />
                                <span className="m-2" >Promotor: {point && point.options && parseFloat(point.options.promoters).toFixed(2)}%</span>
                            </div>
                            <div className="col-md-12  m-2 p-1" >
                                <img src="/img/passive.png" height={30} alt="img" />
                                <span className="m-2" >Passive: {point && point.options && parseFloat(point.options.passive).toFixed(2)}%</span>
                            </div>
                            <div className="col-md-12  m-2 p-1" >
                                <img src="/img/detectors.png" height={30} alt="img" />
                                <span className="m-2" >Detectors: {point && point.options && parseFloat(point.options.detractors).toFixed(2)}%</span>
                            </div>
                        </div>
            
                    </div>
                </div>
            </div>

            <div className="text-center"><p>Impact of NPS: {point && point.options && parseFloat(point.options.y).toFixed(3)}</p></div>
            <div className="text-center"><h6>Total Response: {point && point.options && point.options.total_response}</h6>
            
            </div>
 
        </div>
    )
}
const QuadrantChart = (props) => {
    const {quadrantData,quadrantDataCounts,overAllImpact,overAllNps,
        maxImpact,maxNps,minImpact,minNps
     } = props
    
     console.log(" maxImpact,maxNps,minImpact,minNps", typeof(+maxImpact),maxNps,minImpact,minNps);
  const api = new API()
    const [show, setShow] = useState(false)
  

    const [loader, setLoader] = useState(true)

    const handleModel = async (e, dataName) => {
        setShow(!show)
        let mainCat = { axis: e.point.options, name: dataName }
        console.log(mainCat)
        alert(dataName)

    }

    // useEffect(()=>{
    //     quadrantChartResponse()
    // },[])


  

 console.log("quadrantData",quadrantData,quadrantDataCounts);


    const options = {
        chart: {
            defaultSeriesType: 'scatter',
            borderWidth: 1,
            borderColor: '#ccc',
            marginTop: 20,
            plotBackgroundColor: '#fff',
            zoomType: 'xy',
            height: 800,
          
            },
                
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                shadow: false
            },
            scatter: {
                cursor: 'pointer',
                dataLabels: {
                    crop: false,
                    enabled: true,
                    color: '#444',
                    align: 'left',
                    formatter: function () {
                        return this.point.name;
                    }
                },
                states: {
                    hover: { enabled: false }
                }
            }
        },
        tooltip: {
            useHTML: true,
            formatter() {
                return ReactDOMServer.renderToString(
                <CustomTooltip quadrantDataCounts={quadrantDataCounts} point={this.point} {...props} />);
            },
            pointFormat: '<b>{point.name}:</b> {point.value} '
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        xAxis: {
            min:-100,
            max:100,
            minPadding: 0.099,
            maxPadding: 0.099,
            startOnTick: false,
            endOnTick: false,
            lineColor: 'transparent',
            lineWidth: 0,
            tickColor: '#666',
            tickLength: 3,
            plotLines: [{
                value: 0,
                color: '#666',
                dashStyle: 'solid',
                width: 2,
                zIndex: 5
            }],
            plotBackgroundColor:"green",
            title: {
                style: {
                    fontSize: '15px'
                },
                text: 'NPS Score'
            }
        },
        yAxis: {
            min:0,
            max:1,
            gridLineColor: 'transparent',
            minPadding: 0.099,
            maxPadding: 0.099,
            startOnTick: false,
            endOnTick: true,
            lineColor: 'transprint',
            lineWidth: 0,
            tickColor: '#666',
            tickLength: 3,
            tickWidth: 1,
            plotLines: [{
                value: 0.5,
                color: '#666',
                dashStyle: 'solid',
                width: 2,
                zIndex: 5
            }],
            title: {
                style: {
                    fontSize: '15px'
                },
                text: 'Impact of total NPS ',
                rotation: -90,
                margin: 0
            }
            
        },
        series: [
            {
                type: 'scatter',
                marker: {
                    symbol: 'circle',
                    lineWidth: 1
                },
                // events: {
                //     click: function (event) {
                //       handleModel(event,'Happy Customer')
                //     }
                //   },
                
                data: quadrantData
                // [
                //     { x: 30.5, y: -69, marker: { radius: 100 }, name: 'Addison Square (A)' },

                //     { x: -34, y: 65, marker: { radius: 29, }, name: 'Elton Street (E)' },

                //     { x: 100, y: 60, marker: { radius: 24, }, name: 'Gorton Square (G)' },

                //     { x: -41, y: -50, marker: { radius: 17, }, name: 'Denver Street (D)' },

                //     { x: 67, y: 38, marker: { radius: 17, }, name: 'Carlisle Walk (C)' },

                //     { x: 80, y: -33, marker: { radius: 24, }, name: 'Bilton Village (B)' },

                //     { x: 60, y: 27, marker: { radius: 29, }, name: 'Filton Village (F)' }
                // ]
            }
        ],
        
    }


    


    return (
        <div>
          
{quadrantData && quadrantData.length == 0 ?  <h4 className="text-center no-record">"No Record Found"</h4>:   <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />}
        </div>

    );
}

export default QuadrantChart;
import React, { useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Moment from 'moment'



const VocChart = (props) => {
const {Category,DataSeries,TrendChartResponseFunction,cat,setCat,TotalPercent} = props;


const dataLabels=     {
    enabled: true,
    rotation: 0,
    color: 'black',
    align: 'right',
     format: '{point.y}%', // one decimal
    y: 1, // 10 pixels down from the top
    style: {
        fontSize: '8px',
        fontFamily: 'Verdana, sans-serif'
    },
}


 if(DataSeries){
     let TempObj=[];
     let events =  {
        click: function (event) {
          handleModel(event)
        }
      }
        DataSeries.map((item,index)=>(
         item["dataLabels"]=dataLabels,
         item["events"]=events,
         TempObj.push(item)
     ))
 }


 const handleModel = async (e) => {
    let mainCat=e.point.category
    setCat(e.point.category)
    TrendChartResponseFunction(mainCat)
    }

const condition = Boolean(Math.round(Math.random()))
const max = TotalPercent.length>0 && Math.ceil(TotalPercent[0])


const options = {
    chart: {
        type: 'bar',
        height:'750'
    },
    title: {
        text: cat,
    },
    xAxis: {
        title: {
            text: 'Categories'
        },
        categories:Category==="NA" ? "Sub-Category" : Category, //['Happy Customers', 'Offers & Rewards', 'Complaints', 'App Security', 'Error / Bugs related']
    },
    yAxis: {
        min: 0,
        max: max,
        gridLineColor: 'transparent',
        title: {
            text: "In Percent ( % )"
        }
    },
    legend: {
        reversed: true
    },
    plotOptions: {
        series: {
            stacking: 'normal'
        },
      
    },
    series: DataSeries,


}


    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options} />
     );
}
 
export default VocChart;
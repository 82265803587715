import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import Config from '../../../config';
import { getLocalDataAsObject } from '../../../utils/CoustomStorage';
import UpiQuestionAndResponse from './UpiQuestionAndResponse';
import { currentDay } from '../../../utils/common';
import ImpsQuestionAndResponse from './ImpsQuestionAndResponse';
import FastTageQuestionAndResponse from './FastTagQuestionAndResponse';
import RupayQuestionAndResponse from './RupayQuestionAndResponse';

const QuestionAndResponse = (props) => {
    const api = new API()

    const [loader1, setLoader1] = useState(true)
    const [loader2, setLoader2] = useState(true)
    const [loader3, setLoader3] = useState(true)
    const [loader4, setLoader4] = useState(true)
    const [dpp, setDpp] = useState([])
    const [dppRupay, setDppRupay] = useState([])
    const [dppFastag, setDppFastag] = useState([])  
    const [dppImps, setDppImps] = useState([])  
    const [upiShow,setUpiShow] = useState(false)
    const [rupayShow,setRupayShow] = useState(false)
    const [fastagShow,setFastagShow] = useState(false)
    const [bhimShow,setBhimShow] = useState(false)
    const [netcShow,setNetcShow] = useState(false)
    const [impsShow,setImpsShow] = useState(false)
    const [parameterArr, setParameterArr] = useState([])
    const [parameterArrRupay, setParameterArrRupay] = useState([])
    const [parameterArrFastag, setParameterArrFastag] = useState([])
    const [parameterArrImps, setParameterArrImps] = useState([])
    const [filterObj, setFilterObj] = useState(getLocalDataAsObject('filter') || {})
    const [filterApply, setFilterApply] = useState(false)



    let product = get(filterObj,'product',[{id:1},{id:2},{id:3},{id:4},{id:5},{id:6}]) 
    product = product.length===0 ?  [{id:1},{id:2},{id:3},{id:4},{id:5},{id:6}] : product

    
// Question&Response //
const [questionAndResponseUpi, setQuestionAndResponseUpi] = useState([])
const [noMoreUpi,setNoMoreUpi] = useState(null)

const [questionAndResponseRupay, setQuestionAndResponseRupay] = useState([])
const [noMoreRupay,setNoMoreRupay] = useState(null)

const [questionAndResponseFastag, setQuestionAndResponseFastag] = useState([])
const [noMoreFastag,setNoMoreFastag] = useState(null)

const [questionAndResponseImps, setQuestionAndResponseImps] = useState([])
const [noMoreImps,setNoMoreImps] = useState(null)


// chart  update more //
const [chartsUpiMore, setChartsUpiMore] = useState([]);
const [chartsRupayMore, setChartsRupayMore] = useState([]);
const [chartsFastagMore, setChartsFastagMore] = useState([]);
const [chartsImpsMore, setChartsImpsMore] = useState([]);
//   //   //  //  //  //  //

const dppChart = async()=>{
    let dppResult = await api.get(config.dashboardDppDataUpi)
    let dppChartData = dppResult.data
    // questionAndResUpi(dppChartData[0])
    setDpp(dppChartData)
  } 
 
  const dppChartRupay = async()=>{
    setLoader2(true)
    let dppResultRupay = await api.get(config.dashboardDppDataRupay)
    let dppChartDataRupay = dppResultRupay.data
    // questionAndResRupay(dppChartDataRupay[0])
  setDppRupay(dppChartDataRupay)
  setLoader2(false)
  } 
 
  const dppChartFastag = async()=>{
    setLoader3(true)
    let dppResultFastag = await api.get(config.dashboardDppDataFastag)
    let dppChartDataFastag = dppResultFastag.data
    // questionAndResFastag(dppChartDataFastag[0])
  setDppFastag(dppChartDataFastag)
  setLoader3(false)
  } 

  const dppChartImps = async()=>{
    setLoader4(true)
    let dppResultImps = await api.get(config.dashboardDppDataImps)
    let dppChartDataImps = dppResultImps.data
    // questionAndResImps(dppChartDataImps[0])
  setDppImps(dppChartDataImps)
  setLoader4(false)
  } 
  const questionAndResUpi = async ()=>{
  
    setLoader1(true)
    let questionAndResponseUpiResult = await api.get(config.dashboardQuestionAndResponseUpi,);
     let questionAndResponseData =  questionAndResponseUpiResult.data
    //  if(questionAndResponseData && Array.isArray(questionAndResponseData)){
    //  const arrayData =  Array.isArray(questionAndResponseData) ? questionAndResponseData[0].options : [];
     
    //  }
    //  setParameterArr(arrayData)
     setQuestionAndResponseUpi(questionAndResponseData);
    
       setLoader1(false)
 
    
  }
 
 
  const questionAndResUpis = async (product_id,rating_type,qid,title,stateName)=>{
   
    setLoader1(true)
    let concatArray = []
    if(qid){
       let arrayIndex = null;
 
      questionAndResponseUpi.map((data,index) => {if(data.qid==qid) arrayIndex = index})
 
      if(arrayIndex || arrayIndex === 0){
 
        if(arrayIndex=== 0 ){
         concatArray.push(questionAndResponseUpi[0]);
        }else{
         for(let i=0;i<arrayIndex; i++){
            //
            concatArray.push(questionAndResponseUpi[i]);
            
         }
        }
        
        
      }
      
    }
    let questionAndResponseUpiResults;
    if(title === 'ratingType'){
 
       // questionAndResponseUpiResults = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);
 
      questionAndResponseUpiResults = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
      
    } else  {
       questionAndResponseUpiResults = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=1`);
       
    }
    let questionAndResponseDataUpi = questionAndResponseUpiResults.data
    console.log(questionAndResponseDataUpi,Array.isArray(questionAndResponseDataUpi),'questionAndResponseDataUpi');
   if(questionAndResponseDataUpi && Array.isArray(questionAndResponseDataUpi)){
      const chartsMoreOptionsUpi = questionAndResponseDataUpi;
   const arrayDatasUpi =  Array.isArray(chartsMoreOptionsUpi) ?  questionAndResponseDataUpi.length > 0 ?  questionAndResponseDataUpi[0].options : [] : [];
   setParameterArr(arrayDatasUpi)
   
   setChartsUpiMore(chartsMoreOptionsUpi);
   
 //   [stateName](questionAndResponseDataUpi)
 if(questionAndResponseDataUpi && questionAndResponseDataUpi.length !==0){
    
    let finalArray =concatArray.concat(questionAndResponseDataUpi)
    setQuestionAndResponseUpi(finalArray)
    
    setNoMoreUpi(null)
    
    }else{
       setNoMoreUpi('No Record Found')
    }
    
    setLoader1(false)
 }
   
  }
 
 
  const questionAndResRupay = async ()=>{
    setLoader2(true)
    let questionAndResponseRupayResult = await api.get(config.dashboardQuestionAndResponseRupay);
     let questionAndResponseData = questionAndResponseRupayResult.data
     setQuestionAndResponseRupay(questionAndResponseData)
   
     setLoader2(false)
  }
  const questionAndResRupays = async (product_id,rating_type,qid,title)=>{
    setLoader2(true)
     let concatArray = []
     if(qid){
        let arrayIndex = null;
 
       questionAndResponseRupay.map((data,index) => {if(data.qid==qid) arrayIndex = index})
 
       if(arrayIndex || arrayIndex === 0){
 
         if(arrayIndex=== 0 ){
          concatArray.push(questionAndResponseRupay[0]);
         }else{
          for(let i=0;i<arrayIndex; i++){
             //
             concatArray.push(questionAndResponseRupay[i]);
             
          }
         }
         
         
       }
       
     }
    let questionAndResponseRupayResult;
    if(title === 'ratingType'){
       // questionAndResponseRupayResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);
 
      questionAndResponseRupayResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
     
    } else  {
       questionAndResponseRupayResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=2`);
      
    }
    let questionAndResponseDataRupay = questionAndResponseRupayResult.data
   if(questionAndResponseDataRupay && Array.isArray(questionAndResponseDataRupay)){
      const chartsMoreOptionsRupay = questionAndResponseDataRupay;
   const arrayDatasRupay =  Array.isArray(chartsMoreOptionsRupay) ?  questionAndResponseDataRupay.length > 0 ?  questionAndResponseDataRupay[0].options : [] : [];
   setParameterArrRupay(arrayDatasRupay)
  
   setChartsRupayMore(chartsMoreOptionsRupay);
   if(questionAndResRupay && questionAndResRupay.length!==0){
    let finalArray =concatArray.concat(questionAndResponseDataRupay)
    setQuestionAndResponseRupay(finalArray)
    setNoMoreRupay(null)
    
   }else{
      setNoMoreRupay('No Record Found')
   }
   setLoader2(false)
   }
  }
  
  const questionAndResFastag = async ()=>{
    setLoader3(true)
    let questionAndResponseFastagResult = await api.get(config.dashboardQuestionAndResponseFastag);
     let questionAndResponseData = questionAndResponseFastagResult.data
     setQuestionAndResponseFastag(questionAndResponseData)
     setLoader3(false)
     
  }
 
  const questionAndResFastags = async (product_id,rating_type,qid,title)=>{
    setLoader3(true)
    let concatArray = []
    if(qid){
       let arrayIndex = null;
 
      questionAndResponseFastag.map((data,index) => {if(data.qid==qid) arrayIndex = index})
 
      if(arrayIndex || arrayIndex === 0){
 
        if(arrayIndex=== 0 ){
         concatArray.push(questionAndResponseFastag[0]);
        }else{
         for(let i=0;i<arrayIndex; i++){
            //
            concatArray.push(questionAndResponseFastag[i]);
            
         }
        }
        
        
      }
      
    }
    let questionAndResponseFastagResult;
    if(title === 'ratingType'){
       // questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);
 
      questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
      
    } else  {
       questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=3`);
 
    }
    let questionAndResponseDataFastag = questionAndResponseFastagResult.data
   if(questionAndResponseDataFastag && Array.isArray(questionAndResponseDataFastag)){
      const chartsMoreOptionsFastag = questionAndResponseDataFastag;
   const arrayDatasFastag =  Array.isArray(chartsMoreOptionsFastag) ?  questionAndResponseDataFastag.length > 0 ?  questionAndResponseDataFastag[0].options : [] : [];
   setParameterArrFastag(arrayDatasFastag)
  
   setChartsFastagMore(chartsMoreOptionsFastag);
  if(questionAndResFastag && questionAndResFastag.length !==0){
    let finalArray =concatArray.concat(questionAndResponseDataFastag)
    setQuestionAndResponseFastag(finalArray)
    setNoMoreFastag(null)
    
  }else{
     setNoMoreFastag('No Record Found')
  }
  setLoader3(false)
   }
  }
 
  const questionAndResImps = async ()=>{
    setLoader4(true)
    let questionAndResponseImpsResult = await api.get(config.dashboardQuestionAndResponseImps);
     let questionAndResponseData = questionAndResponseImpsResult.data
     setQuestionAndResponseImps(questionAndResponseData)
     setLoader4(false)
     
  }
 
  const questionAndResImps1 = async (product_id,rating_type,qid,title)=>{
    setLoader4(true)
    let concatArray = []
    if(qid){
       let arrayIndex = null;
 
      questionAndResponseImps.map((data,index) => {if(data.qid==qid) arrayIndex = index})
 
      if(arrayIndex || arrayIndex === 0){
 
        if(arrayIndex=== 0 ){
         concatArray.push(questionAndResponseImps[0]);
        }else{
         for(let i=0;i<arrayIndex; i++){
            //
            concatArray.push(questionAndResponseImps[i]);
            
         }
        }
        
        
      }
      
    }
    let questionAndResponseImpsResult;
    if(title === 'ratingType'){
       // questionAndResponseImpsResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);
 
      questionAndResponseImpsResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
      
    } else  {
       questionAndResponseImpsResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=6`);
 
    }
    let questionAndResponseDataImps = questionAndResponseImpsResult.data
   if(questionAndResponseDataImps && Array.isArray(questionAndResponseDataImps)){
      const chartsMoreOptionsImps = questionAndResponseDataImps;
   const arrayDatasImps =  Array.isArray(chartsMoreOptionsImps) ?  questionAndResponseDataImps.length > 0 ?  questionAndResponseDataImps[0].options : [] : [];
   setParameterArrImps(arrayDatasImps)
  
   setChartsImpsMore(chartsMoreOptionsImps);
  if(questionAndResImps && questionAndResImps.length !==0){
    let finalArray =concatArray.concat(questionAndResponseDataImps)
    setQuestionAndResponseImps(finalArray)
    setNoMoreImps(null)
    
  }else{
     setNoMoreImps('No Record Found')
  }
  setLoader4(false)
   }
  }

  // life cycle //
  useEffect(() => {
  
    dppChart()
    dppChartRupay()
    dppChartFastag()
    dppChartImps()
    questionAndResUpi()
    questionAndResRupay()
    questionAndResFastag()
    questionAndResImps()
 
    
   }, [])





   
    return ( 
        <div >
        <div className="col-md-12">
             {product && product.find((item)=>item.id===1)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false && impsShow===false) || upiShow ) &&  <UpiQuestionAndResponse title={"UPI"} loader={loader1} questionAndResponse={questionAndResponseUpi} dpp={dpp} noMoreUpi={noMoreUpi} parameterArr={parameterArr} chartsUpiMore={chartsUpiMore} questionAndResUpis={questionAndResUpis} questionAndResUpi={(value) => questionAndResUpi(value)}/> }
             { product && product.find((item)=>item.id===2)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false && impsShow===false) || rupayShow) &&  <RupayQuestionAndResponse title={"Rupay"} loader={loader2} questionAndResponse={questionAndResponseRupay} dpp={dppRupay} noMoreRupay={noMoreRupay} parameterArrRupay={parameterArrRupay} chartsRupayMore={chartsRupayMore} questionAndResRupays={questionAndResRupays} questionAndResRupay={(value) => questionAndResRupay(value)}/> }
    { product && product.find((item)=>item.id===3)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false && impsShow===false) || fastagShow) &&  <FastTageQuestionAndResponse title={"FASTag"} loader={loader3} questionAndResponse={questionAndResponseFastag} dpp={dppFastag} noMoreFastag={noMoreFastag}  parameterArrFastag={parameterArrFastag} chartsFastagMore={chartsFastagMore} questionAndResFastags={questionAndResFastags} questionAndResFastag={(value) => questionAndResFastag(value)}/> }
 { product && product.find((item)=>item.id===5)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false  && impsShow===false) || impsShow) &&  <ImpsQuestionAndResponse title={"IMPS"} loader={loader4} questionAndResponse={questionAndResponseImps} dpp={dppImps} noMoreImps={noMoreImps}  parameterArrImps={parameterArrImps} chartsFastagMore={chartsImpsMore} questionAndResImps1={questionAndResImps1} questionAndResImps={(value) => questionAndResImps(value)}/> }
 
        </div>
        </div>
     );
}
 
export default QuestionAndResponse;
import React, { useEffect, useState } from 'react';
import QuestionsAndResponsesChart from '../../charts/QuestionsAndResponsesChart'
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash'
import QuestionsAndResponsesChartSub from '../../charts/QuestionsAndResponsesChartSub';
import Skeleton from 'react-loading-skeleton';

function FastTageQuestionAndResponse(props) {
  const api = new API()
  const {questionAndResponse,dpp,questionAndResUpi,loader,parameterArrFastag,questionAndResFastags,chartsFastagMore,noMoreFastag} = props;

  const [questionShow,setQuestionShow] = useState(false)
  const [question,setQuestion] = useState([])

  const questionShowHide =()=>{
    setQuestionShow(!questionShow)
  }
  const clicked = (e) => {
    

    const {qid,option_id,value} = e.point.options
    
  const product_id = value
    questionShowHide()
   const rating_type = option_id
    // questionAndResFastags(product_id,rating_type,qid)
    questionAndResFastags(3,rating_type,qid, 'ratingType')
  }

  const clickedUpi = (e) => {
    const product_id = 1
      questionShowHide()
      const rating_type = e.point.options.value
    // questionAndResFastags(product_id,rating_type)
      questionAndResFastags(3,rating_type,0,'ratingType')
    }


  return (
    <div className="container-fluid">
    <div className="card">
    <div className="card-header">
       <h5 className="fastagQueAndRes1" >FASTag Questions & Responses</h5>
    </div>
    <div className="card-body">
    <div className="row">
    <div className="col-md-6">
    {loader == true && dpp && dpp.data && dpp.data.length < 0  ? <Skeleton count={15}/> : 
       <div className="card-box">
       <QuestionsAndResponsesChart questionAndResponseChart={dpp} onClick={(e)=>clickedUpi(e)} title={"FASTag Promoter, Passive, Detractor Rating"}/>
       </div>
}
     </div>
     {/* <div className="col-md-6">
       <div className="card-box">
       <QuestionsAndResponsesChart questionAndResponseChart={parameterArrFastag} onClick={(e)=>clicked(e)} title={"Parameter wise responses"}/>
       </div>
     </div> */}
     {loader  == true && Array.isArray(questionAndResponse) && questionAndResponse &&  questionAndResponse.data && 
            questionAndResponse.data.length > 0 ? <Skeleton count={15}/> : 
            <>
    {Array.isArray(questionAndResponse) && questionAndResponse.length > 0 && questionAndResponse.map((Item,index)=>(
            <div key={index} className="col-md-6">
               <div  className="card-box">
              <QuestionsAndResponsesChartSub questionAndResponseChart={get(Item,"options",{})} onClick={(e)=>clicked(e)} title={Item.question}/>
              </div> 
            </div>))}
            {noMoreFastag &&<div  className="col-md-6">
               <div  className="card-box">
              <div>{noMoreFastag}</div>
               </div>
               </div>}
               </>}
   </div>
       </div>
    </div>
    </div>
  )
}
export default FastTageQuestionAndResponse
import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Switch, BrowserRouter, HashRouter, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultHeader from './common/defaultHeader';
import Dashboard from './components/dashboard/dashboard';
import UserManagement from './components/user-management/userManagement';
import ReportAnalysis from './components/report-analysis/reportAnalysis';
import SentimentAnalysis from './components/sentiment-analysis/sentimentAnalysis';
import Survey from './components/survey/survey'
import Report from './components/report/report'
import CreateNewSurvey from './components/survey/createNewSurvey';
import FinalSurvey from './components/survey/common/surveyFile/finalSurvey';
import EditSurvey from './components/survey/common/surveyFile/editSurvey/editSurvey';
import LogicSurvey from './components/survey/common/surveyFile/logicSurvey/logicSurvey';
import CreateSurvey from './components/survey/common/surveyFile/createSurvey/createSurvey';
import Login from './common/login';
import { getLocalDataAsObject } from './utils/CoustomStorage';
import { withCookies } from 'react-cookie';
import DoYourSurvey from './components/doYourSurvey/doYourSurvey';
import ListViewTable from './common/listViewTable';
import PartnerWiseListTable from './common/partnerWiseListTable';
import moment from 'moment'
import AutoLogout from './common/autoLogout';
import DumpData from './components/report/dumpData';
import ScrollTop from './common/scrollTop';
import QuestionAndResponse from './components/dashboard/common/quetionAndResponse';
import VocReport from './components/report/vocReport';
import Voc from './components/Voc';

import NpsUpdateForBuds from './components/npsUpdateForBuds/NpsUpdateForBuds';

const App = (props) => {
  
  const [breadcrumb, setBreadcrumb] = useState({ label: 'Dashboard', link: '/' })
  
  let path = window.location.hash
  let token = props.cookies.get("token")


 
  useEffect(() => {

    // switch (path) {
    //   case '#/userManagement':
    //     setBreadcrumb({label:'User Management', link:'/userManagement'})
    //     break;
    //     case '#/':
    //       setBreadcrumb({label:'Dashboard', link:'/'})
    //       break;
    //     case '#/reportAnalysis':
    //       setBreadcrumb({label: 'report Analysis', link:'/reportAnalysis'})
    //       break;
    //       case '#/sentimentAnalysis':
    //         setBreadcrumb({label: 'sentiment Analysis', link:'/sentimentAnalysis'})
    //       default:
    //     setBreadcrumb({label:'Dashboard', link:'/'})
    //     break;
    // }
  }, [path])

 

  if (token) {
    return (
      <div className=" site-wrap">
        {/* <BrowserRouter> */}
        <HashRouter>

          <ToastContainer />
          
          <ScrollTop>
          <DefaultHeader breadcrumb={breadcrumb} {...props} />
          <Switch>
            {/* <Route path="/dashboard" component={Dashboard}  /> */}
            <Route path="/userManagement" name="UserManagement" component={(props) => <AutoLogout ComposedClass={UserManagement} {...props} />} />
            <Route path="/reportAnalysis" name="ReportAnalysis" component={(props) => <ReportAnalysis {...props} />} />
            <Route path="/voc" name="VOC" component={(props) => <Voc {...props} />} />
            <Route path="/sentimentAnalysis" name="SentimentAnalysis" component={(props) => <SentimentAnalysis {...props} />} />
            <Route path="/dump-data" name="dump-data" component={(props) => <AutoLogout ComposedClass={DumpData} {...props} />} />
            <Route path="/report" name="Report" component={(props) => <AutoLogout ComposedClass={Report} {...props} />} />
            <Route path="/question-response" name="Question And Response" component={(props) => <AutoLogout ComposedClass={QuestionAndResponse} {...props} />} />
            <Route path="/createssurvey" name="CreateSurvey" component={(props) => <CreateNewSurvey {...props} />} />
            <Route path="/doyoursurvey" name="DoYourSurvey" component={(props) => <DoYourSurvey {...props} />} />
            <Route path="/finalSurvey" name="FinalSurvey" component={(props) => <FinalSurvey {...props} />} />
            <Route path="/editSurvey" name="EditSurvey" component={(props) => <EditSurvey {...props} />} />
            <Route path="/logicSurvey" name="LogicSurvey" component={(props) => <LogicSurvey {...props} />} />
            <Route path="/createSurvey" name="CreateSurvey" component={(props) => <CreateSurvey {...props} />} />
            <Route path="/listview/:id" name="ListViewTable" component={(props) => <ListViewTable {...props} />} />
            <Route path="/vocreport/:id" name="Voc Report Table" component={(props) => <VocReport {...props} />} />
            <Route path="/partnerwiselistview/:id" name="PartnerWiseListTable" component={(props) => <PartnerWiseListTable {...props} />} />
            <Route path="/dashboard" name="Dashboard" component={(props) => <AutoLogout ComposedClass={Dashboard} {...{token}} {...props} />} />
            
            <Route path="/npsupdateforbuds" name="NPS Update For Buds" component={(props) => <NpsUpdateForBuds {...props} />} />
            
            <Route render={() => (<Redirect to='/dashboard' />)} />   
            
            

          </Switch>
          </ScrollTop>
        </HashRouter>
        {/* </BrowserRouter> */}
      </div>
    );
  } else {
    return (
      <div className=" site-wrap">
        {/* <BrowserRouter> */}
        <HashRouter>

          <ToastContainer />
          <Switch>
            <Route path="/login" name="Login" component={(props) => <Login {...props} />} />
            <Route render={() => (<Redirect to='/login' />)} />

          </Switch>
        </HashRouter>
        {/* </BrowserRouter> */}
      </div>
    );
  }
}

export default withCookies(App);

import React, { useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const QuestionsAndResponsesChart = (props) => {

    const {title,questionAndResponseChart,onClick} = props
const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        margin: [6, 6, 6, 6],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0
    },
    title: {
        text: title //'Parameter wise Responses'
    },
    exporting: {
    enabled: false
  },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
         size:'60%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            },
            colors: [
                '#55BF3B',  // yellow
                '#DDDF0D', // green
                '#DF5353', // red
              ],
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: questionAndResponseChart,
        events: {
            click:  function (event) {
                onClick(event)
            }
        }
   
     }]
}

    return (
        <div> 
      <HighchartsReact
            highcharts={Highcharts}
            options={options}
            style={{width:450,height:400}}
        /> 
     </div>
     );
}
 
export default QuestionsAndResponsesChart;
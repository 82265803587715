import React, { useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;

const TablePartner = (props) => {
  const {partnerArr,onChange,selectedPartner} = props;
const[show,setShow] = useState(false)
   console.log(partnerArr,'partnerArr');

      
const handleChange = (value) => {
  onChange(value,'partner')
}
    
   

   


    return ( 
     
          <div className="col-lg-3 mt-4 px-1 ">
          <Select value={selectedPartner} placeholder={'select partner'} mode="multiple" onChange={handleChange} style={{width:'100%'}}>
          {partnerArr.map((data,index) => (
            <Option key={index} value={data.partner_name}>{data.partner_name}</Option>
          ))}
          </Select>
          </div>
     
     );
}
 
export default TablePartner;
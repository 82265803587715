import React, { useState, useEffect, useRef, createRef } from 'react';
import { Table } from 'reactstrap';
// import Pagination from "react-js-pagination";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Loader from './loader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { CSVLink, CSVDownload } from "react-csv";
import Pdf from "react-to-pdf";
import _ from "lodash";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import DateRangePicker from './dateRangePicker';

import { PDFExport } from '@progress/kendo-react-pdf';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const AllPartnerStateWiseTable = (props) => {
  const csvLink = useRef()
  const inputEl = useRef(null);
  const ref = createRef();
  const api = new API()
  // const {surveyList} = props;
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [responseList, setResponseList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)
  const [responseDownloadData, setResponseDownloadData] = useState([])
  const [sortConfig, setSortConfig] = useState(config);
  const [itemPerPage, setItemPerPage] = useState(5)
  const [searchProduct, setSearchProduct] = useState('')
  const [searchPartner, setSearchPartner] = useState('')
  const [searchZone, setSearchZone] = useState('')
  const [searchCity, setSearchCity] = useState('')
  const [searchState, setSearchState] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isAsending, setIsAsending] = useState(false)
  const [more, setMore] = useState(false);


  useEffect(() => {
    responseListResponse()
    responseDownload()
  }, [])

  useEffect(() => {
    responseDownload()
    responseListResponse()
  }, [page, itemPerPage, searchProduct, searchPartner, startDate,searchZone, searchCity,searchState, endDate])



  const responseListResponse = async () => {
 let Data = { page_no: page, item_per_page: itemPerPage, partner: searchPartner, product: searchProduct,
   start_date: startDate, end_date: endDate, zone:searchZone,city:searchCity,state:searchState }

    if (Data.partner === "") {
      delete Data.partner
    }
    if (Data.product === "") {
      delete Data.product
    }
    if (Data.start_date === "") {
      delete Data.start_date
    }
    if (Data.end_date === "") {
      delete Data.end_date
    }
    if (Data.zone === "") {
      delete Data.zone
    }
    if (Data.city === "") {
      delete Data.city
    }
    if (Data.state === "") {
      delete Data.state
    }
    const result = await api.post(config.reportPartnerStateWiseList, Data)
    let responseData = result && result.data && result.data.finalResult
    let responseListData = result && result.data
   
    setResponseList(responseData)
    setTotalItem(responseListData.total_item)
    setTotalPage(responseListData.total_page)
    setTotalData(responseListData.last_index)
    setCurrentDataCount(responseListData.current_page)
    setFirstIndexItem(responseListData.first_index) 
  }

  const handlePageChange = async (event, value) => {
   if (totalPage !== 0) {
      setPage(value);
      setLoader(false)
    }
  }



  const responseDownload = async (Id) => {
    let Datas = {
      partner: searchPartner,
      product: searchProduct,
      start_date: startDate,
      end_date: endDate,
      zone:searchZone,
      city:searchCity,
      state:searchState,
    }
    if (Datas.partner === "") {
      delete Datas.partner
    }
    if (Datas.product === "") {
      delete Datas.product
    }
    if (Datas.start_date === "") {
      delete Datas.start_date
    }
    if (Datas.end_date === "") {
      delete Datas.end_date
    }
    if (Datas.zone === "") {
      delete Datas.zone
    }
    if (Datas.city === "") {
      delete Datas.city
    }
    if (Datas.state === "") {
      delete Datas.state
    }
    const result = await api.post(config.statewiseNpsResponseDownload, Datas)
    setResponseDownloadData(result.data) 
    setLoader(false)
  }

  const sortFilter = (sortBy, order) => {
    let tem = _.orderBy(responseList, [sortBy], [order])
    setResponseList(tem);
    setIsAsending(prev => !prev) 
    setLoader(false)
  };


  const onButtonPdfClick = () => {
    inputEl.current.save();
  };


  return (

    <div className=" App site-wrap container mt-5">
      <div className="bg-dark card-body py-3 "><h5 className="mb-0 text-white">ZONE WISE SURVEY RESPONSE</h5></div>

      {loader == true ?
        <Skeleton count={10} /> :
        <>
          <div className="text-left py-4 d-flex bg-light card-body justify-content-between mb-4">
            <div className>
              <label for="email">Rows per page:</label>
              <select onChange={(e) => {setItemPerPage(e.target.value);setPage(1)}} value={itemPerPage} className="form-control" id="sel1">
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
            <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate} />
            <ButtonDropdown isOpen={more} toggle={() => setMore(!more)}>
              <DropdownToggle className="btn white-btn mt-4">
                <i className="fa  fa-bars ml-2 btn color-white" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                {responseList && responseList.length === 0 ? 
                  <tr><td colspan="10">Please search correct key words</td></tr>
                    :
                  <CSVLink color="black" data={responseDownloadData}
                    filename={"Survey-Response-List.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink>
}
                </DropdownItem>
                <DropdownItem>
                <span onClick={onButtonPdfClick}>Generate Pdf</span>
                
                </DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            {/* {more ?  <div className="d-flex">

              <CSVLink data={responseDownloadData}
                filename={"Survey-Response-List.csv"} ref={csvLink}
              ><Button className="color-primary btn btn-secondary  mr-3 mt-4 btn btn-secondary">Excel Download</Button>
              </CSVLink>
              <Pdf targetRef={ref} filename={"Survey-Response-List.pdf"}>
                {({ toPdf }) => <button className="color-primary btn btn-secondary  mr-3 mt-4 btn btn-secondary" onClick={toPdf}>Generate Pdf</button>}
              </Pdf>
              <button filename={"Survey-Response-List.jpeg"} className="color-primary btn btn-secondary mr-3  mt-4 btn btn-secondary" onClick={() => exportComponentAsJPEG(ref)}>
                JPEG Download
                </button>
              <button filename={"Survey-Response-List.png"} className="color-primary btn btn-secondary   mt-4 btn btn-secondary" onClick={() => exportComponentAsPNG(ref)}>
                PNG Download
                </button>
            </div> : null} */}
            {/* <i className="fa  fa-bars ml-2 btn color-info mt-4" onClick={()=>setMore(!more)}/> */}
          </div>

          {/* <div className="bg-dark card-body py-3 text-white"><h5 className="mb-0">{responseList && responseList.data && responseList.data.product_name}</h5></div> */}
          <div className="table text-center mb-4">


            <>
            <PDFExport
                    forcePageBreak=".page-break"
                    paperSize={'A4'}
                    margin="1.5cm"
                    scale={0.7}
                    fileName={ 'Survey-Response-List.pdf'}
                    ref={inputEl}
                >
              <table ref={ref} className="table table-Common table-bordered table-striped" >



                <thead>
                  <tr className="bg-secondary text-white">

                    <th>S No.</th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('product_name', isAsending ? 'desc' : 'asc')}>Product <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /> </div></th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('parnter_name', isAsending ? 'desc' : 'asc')}>Partner <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                     <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('zone', isAsending ? 'desc' : 'asc')}>Zone <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('total_count', isAsending ? 'desc' : 'asc')}>Responses <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('detractors', isAsending ? 'desc' : 'asc')}>Detractor <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('passives', isAsending ? 'desc' : 'asc')}>Passive <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('promoters', isAsending ? 'desc' : 'asc')}>Promoter <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                    <th><div style={{ cursor: 'pointer' }} onClick={() => sortFilter('nps_score', isAsending ? 'desc' : 'asc')}>NPS Score <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control search-filed" value={searchProduct} onChange={(e) => setSearchProduct(e.target.value)} placeholder="Search.." />
                        <i className="input-group-text pt-2 fa fa-search search-icon" aria-hidden="true" />
                        <div className="input-group-append">
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control search-filed" value={searchPartner} onChange={(e) => setSearchPartner(e.target.value)} placeholder="Search.." />
                        <i className="input-group-text pt-2 fa fa-search search-icon" aria-hidden="true" />
                        <div className="input-group-append">
                        </div>
                      </div>
                    </td>
                    <td>
                    <div className="input-group">
                        <input type="text" className="form-control search-filed" value={searchZone} onChange={(e) => setSearchZone(e.target.value)} placeholder="Search.." />
                        <i className="input-group-text pt-2 fa fa-search search-icon" aria-hidden="true" />
                        <div className="input-group-append">
                        </div>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {responseList && responseList.length === 0 ? <tr><td colspan="10">"No Result"</td></tr> :

                    responseList && responseList.map((data, index) => (
                      <tr key={index}>
                        <th scope="row">{firstIndexItem + index}</th>
                        <td>{data.product_name}</td>
                        <td>{data.parnter_name}</td>
                        <td>{data.zone}</td>
                        <td>{data.total_count}</td>
                        <td>{data.detractors}</td>
                        <td>{data.passives}</td>
                        <td>{data.promoters}</td>
                        <td>{data.nps_score} <span className="curtom-circle" style={{ backgroundColor: data.color_code }}></span></td>
                      </tr>
                    ))}

                </tbody>

              </table>
              <div className="text-center d-flex justify-content-between align-items-center pb-5">
                <Pagination count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="primary" align="center" />

                <h5 className="float-left mb-0">{totalItem === 0 ? 0 : firstIndexItem} - {totalData} of {totalItem} items </h5>

              </div>
              </PDFExport>
            </>


          </div>
        </>
      }
    </div>
  );
}

export default AllPartnerStateWiseTable;
import React, { useState, Fragment, useEffect } from 'react';
import SubNavBar from './subNavBar';
import { Link, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash'
import Dashboard from '../components/dashboard/dashboard';
import Breadcrumbs from './breadcrumbs';
import { clearLocalData, getLocalDataAsObject } from '../utils/CoustomStorage';
import { withCookies } from 'react-cookie';

const DefaultHeader = ({cookies}) => {
    const [pathUrl,setPathUrl] = useState(window.location.hash)
    
// ROLE//
const [userRole,setUserRole] = useState({});
    const pathname = window.location.hash
    // useEffect (()=>{
    //     switch (pathname) {
    //         case '#/userManagement':
    //           setBreadcrumb({label:'User Management', link:'/userManagement'})
    //           break;
    //           case '#/':
    //             setBreadcrumb({label:'Dashboard', link:'/'})
    //             break;
    //           case '#/reportAnalysis':
    //             setBreadcrumb({label: 'report Analysis', link:'/reportAnalysis'})
    //         default:
    //           setBreadcrumb({label:'Dashboard', link:'/'})
    //           break;
    //       }
    //     },[pathname])
    useEffect(()=>{
        setPathUrl(window.location.hash)
        let title = 'NPCI';
        document.title = title + ` | ${window.location.hash === "#/dashboard" ? "Dashboard" : "" ||
         window.location.hash === "#/userManagement" ? "User Mangement" : ""
        || window.location.hash === "#/report" ? "Report" : ""  ||
        window.location.hash === "#/listview" ? "Dump List" : ""  ||
         window.location.hash === "#/dump-data" ? "Dump Data" : "" ||
         window.location.hash === "#/question-response" ? "Question Response" : ""
         || window.location.hash === "#/login" ? "LOGIN" : ""}`;
    },[pathUrl])

    useEffect(()=>{
        if (getLocalDataAsObject('user')) {
            
            setUserRole(getLocalDataAsObject('user'))
            }
    },[])
    const logout = () => {
        clearLocalData()
         cookies.remove("token")
      }
      
    return (
        <Fragment>
            <div id="sidebar-wrapper">
                <div className="sidebar-heading white-font">LOGO</div>
                <div className="list-group list-group-flush">
                    <Link to="/dashboard" className={(pathname == "#/dashboard") || (pathname == "#/") ? "list-group-item" : "list-group-item"}><img src="img/npci-logo/dashboard.png" title={"Dashboard"} style={{ width: '30px' }} /></Link>
                    {/* <Link to="/doyoursurvey" className={pathname == "#/doyoursurvey" ? "list-group-item active" : "list-group-item"}><img src="img/npci-logo/report.png" style={{ width: '30px' }} /></Link> */}
                    {/* <Link to="/userManagement" className={pathname == "#/userManagement" ? "list-group-item " : "list-group-item"}><img src="img/npci-logo/user-management.png" title={"User Management"} style={{ width: '30px' }} /></Link> */}
                    {/* <Link to="/sentimentAnalysis" className={pathname == "#/sentimentAnalysis" ? "list-group-item active" : "list-group-item"}><img src="img/npci-logo/eye.png" style={{ width: '30px' }} /></Link> */}
                   <Link to="/report" className={pathname == "#/report" ? "list-group-item " : "list-group-item"}><img src="img/npci-logo/report-analysis.png" title={"Reports"} style={{ width: '30px' }} /></Link>
                   {userRole && userRole.role===2 ? null : <Link to="/dump-data" className={pathname == "#/dump-data" ? "list-group-item " : "list-group-item"}><img src="img/dump-data.png" title={"Dump Data"} style={{ width: '48px',marginLeft:'-10px',marginTop:'-9px' }}/></Link>}
                    <Link to="/question-response" className={pathname == "#/question-response" ? "list-group-item active" : "list-group-item"}><img src="img/question-res.png" title={"Question And Response"} style={{ width: '48px',marginLeft:'-10px',marginTop:'-9px' }}  /></Link>
                    <Link to="/voc" className={pathname == "#/voc" ? "list-group-item active" : "list-group-item"}><img src="img/voc.png" title={"VOC"} style={{ width: '32px',marginLeft:'-5px',marginTop:'-9px' }}  /></Link>
                    <Link to="/npsupdateforbuds" className={pathname == "#/npsupdateforbuds" ? "list-group-item active" : "list-group-item"}><i title={"NPS BUDS"} className="fa fa-bullseye text-info mr-3 f-34" aria-hidden="true"/></Link>
                  
                </div>
            </div>
            <div id="page-content-wrapper">
                <nav className="mb-1 navbar navbar-expand-lg head justify-content-between">

                    <div className="col-md-6 col-6 text-left">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <Link to="/dashboard"  > <img className="left" src="img/left-logo.png" /> </ Link>
                            </div>
                            {/* <Breadcrumbs breadcrumb={props.breadcrumb}/> */}
                        </div>
                    </div>
                    <div className="col-md-6 col-6 d-md-block d-lg-block d-xl-block d-none text-right custom-nav">
                        <ul className="navbar-nav ">
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link" data-toggle="dropdown" href="#" style={{ paddingBottom: '0px' }}>
                                    <i className="fas fa-bell" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: 'inherit', marginTop: '19px', right: '0px' }}>
                                    <ul className="navbar-nav dropdown-item dropdown-header">
                                        <li><img src="img/fire.png" /><span>1,50,000 Survey Triggered</span></li>
                                        <li><img src="img/block.png" /><span>20,000 Undelivered Survey</span></li>
                                        <li><img src="img/delivery-truck.png" /><span>1,33,000 Survey delivered </span></li>
                                        <li><img src="img/001-email.png" /><span>1,500 Responses Received</span></li>
                                        <li><img src="img/001-volume.png" /><span>1% Response Percentage</span></li>
                                    </ul>
                                    <div className="dropdown-divider" />
                                    <a href="#" className="dropdown-item dropdown-footer"><strong>Today :-</strong> </a>
                                    <a href="#" className="dropdown-item">
                                        Dashboard Login
                                     <span className="float-right text-muted text-sm">| 01 : 14 | </span>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        Target Achieved for Product UPI 15100 / 15000 for this month.
                                    <span className="float-right text-muted text-sm">| 01 : 30 | </span>
                                    </a>
                                    <div className="dropdown-divider" />
                                    <a href="#" className="dropdown-item dropdown-footer"><strong>Yesterday :-</strong> </a>
                                    <a href="#" className="dropdown-item">
                                        Dashboard Login
                                      <span className="float-right text-muted text-sm">| 01 : 14 | </span>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        Target Achieved for Product UPI 15100 / 15000 for this month.
                                 <span className="float-right text-muted text-sm">| 01 : 30 | </span>
                                    </a>
                                    <div className="dropdown-divider" />
                                    <a href="#" className="dropdown-item dropdown-footer"><strong>Last Week  :-</strong> </a>
                                    <a href="#" className="dropdown-item">
                                        Dashboard Login
                             <span className="float-right text-muted text-sm">| 01 : 14 | </span>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        Target Achieved for Product UPI 15100 / 15000 for this month.
                            <span className="float-right text-muted text-sm">| 01 : 30 | </span>
                                    </a>
                                    <div className="dropdown-divider" />
                                </div>
                            </li>
                             */}
                            {/* <li className="nav-item dropdown mr-0">
                                <a className="nav-link">
                                    <img src="img/001-exit.png" />
                                </a>
                            </li> */}
                            <li className="nav-item dropdown mr-0 mt-1">
                                <a className="nav-link btn">
                                <img onClick={logout} src="img/logout.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 d-block d-md-none d-lg-none d-xl-none text-right"
                    // onClick="openMobileTopbar()"
                    >
                        <i className="fas fa-bars" />
                    </div>
                </nav>

                <div style={{ height: "60px" }}></div>


            </div>
        </Fragment>
    );
}

export default withCookies(DefaultHeader);
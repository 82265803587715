export default {
    development: {
      // site_api: 'http://localhost:3002',
      // site_api: 'https://103.231.8.86:3000', 
      site_api: 'https://npci.surveycxm.com:3000',
    },
    production: {
      site_api: 'https://npci.surveycxm.com:3000',
      
    },
  };

 export const base_url = "https://npci.surveycxm.com:3000"
import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
// import Pagination from "react-js-pagination";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { CSVLink, CSVDownload } from "react-csv";
import Pdf from "react-to-pdf";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import _ from "lodash";
import DateRangePicker from './dateRangePicker';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PDFExport } from '@progress/kendo-react-pdf';
import DumpDataList from '../common/dumpDataList';



const ListViewTable = (props) => {
  const csvLink = useRef()
  const inputEl = useRef(null);
  const ref = React.createRef();
  const api = new API()
  const Id = props.match.params.id;
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [surveyResponseList, setSurveyResponseList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)
  const [responseDownloadData, setResponseDownloadData] = useState([])
  const [itemPerPage, setItemPerPage] = useState(10)
  const [searchMobile, setSearchMobile] = useState('')
  const [searchPartner, setSearchPartner] = useState('')
  const [sendStatus, setSendStatus] = useState('')
  const [surveyResponse, setSurveyResponse] = useState('')
  const [isAsending, setIsAsending] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [more, setMore] = useState(false);
  const [disable,setDisable] = useState(true)

  useEffect(() => {
    ListViewResponse()
    // responseDownload(Id)
   
  }, [])

  useEffect(() => {
    ListViewResponse()
    // responseDownload(Id)
  }, [page, itemPerPage, sendStatus, surveyResponse, searchPartner, searchMobile, startDate, endDate])



  const ListViewResponse = async () => {
    let
      Data = {
        page_no: page, survey_id: Id, item_per_page: itemPerPage,
        send_status: sendStatus, survey_response: surveyResponse, mobile: searchMobile, partner: searchPartner,
        start_date: startDate, end_date: endDate
      }
    if (Data.mobile === "") {
      delete Data.mobile
    }
    if (Data.send_status === "") {
      delete Data.send_status
    }
    if (Data.survey_response === "") {
      delete Data.survey_response
    }
    if (Data.partner === "") {
      delete Data.partner
    }
    if (Data.start_date === "") {
      delete Data.start_date
    }
    if (Data.end_date === "") {
      delete Data.end_date
    }

    const result = await api.post(config.reportSurveyResponseList, Data)
    let responseData = result && result.data && result.data.finalResult
    let surveyResponseData = result && result.data

    setSurveyResponseList(responseData)
    setTotalItem(surveyResponseData && surveyResponseData.total_item)
    setTotalPage(surveyResponseData && surveyResponseData.total_page)
    setTotalData(surveyResponseData && surveyResponseData.last_index)
    setCurrentDataCount(surveyResponseData && surveyResponseData.current_page)
    setFirstIndexItem(surveyResponseData && surveyResponseData.first_index)
    setLoader(false)


  }

  const handlePageChange = async (event, value) => {
    setPage(value);
    setLoader(false)
  }



  const responseDownload = async (Id,done=null) => {

   
    let Datas = {
      survey_id: Id, send_status: sendStatus, survey_response: surveyResponse, mobile: searchMobile, partner: searchPartner,
      start_date: startDate, end_date: endDate
    }
    if (Datas.mobile === "") {
      delete Datas.mobile
    }
    if (Datas.send_status === "") {
      delete Datas.send_status
    }
    if (Datas.survey_response === "") {
      delete Datas.survey_response
    }
    if (Datas.partner === "") {
      delete Datas.partner
    }
    if (Datas.start_date === "") {
      delete Datas.start_date
    }
    if (Datas.end_date === "") {
      delete Datas.end_date
    }
    const result = await api.post(config.reportSurveyResponseDownload, Datas)
    setResponseDownloadData(result.data)
    done && done()
    console.log(result);
    setLoader(false)
    setDisable(false)
  }

  const sortFilter = (sortBy, order) => {
    let tem = _.orderBy(surveyResponseList, [sortBy], [order])
    console.log(tem, "tem");
    setSurveyResponseList(tem);
    setIsAsending(prev => !prev)
    setLoader(false)
  };
  const onButtonPdfClick = () => {
    inputEl.current.save();
  };
  return (
<>
    <div className="container">
      <div className="bg-dark card-body py-3  text-center"><h5 className="mb-0 text-white">Survey Response</h5></div>
      {loader == true ?
        <Skeleton count={20} /> :
        <>
          <div className="text-left py-4 d-flex bg-light card-body justify-content-between">
            <div className>
              <label for="email">Rows per page:</label>
              <select onChange={(e) => setItemPerPage(e.target.value)} value={itemPerPage} className="form-control" id="sel1">
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>

            <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate} />
            {/* { disable  ?  <DropdownToggle className="btn white-btn mt-4">
              <DropdownItem>Please Wait For Download</DropdownItem>
            <Skeleton circle={true} height={25} width={25} /> 
              </DropdownToggle> :  <ButtonDropdown  isOpen={more} toggle={() => setMore(!more)}>
              <DropdownToggle className="btn white-btn mt-4">
                <i    className="fa  fa-bars ml-2 btn color-white" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <CSVLink asyncOnClick={true}
                  
                   color="black" data={responseDownloadData}
                    filename={"Survey-Response-List.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink>
                </DropdownItem>

                <DropdownItem>
                  <span onClick={onButtonPdfClick}>Generate Pdf</span>

                </DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
} */}
          </div>
          {/* <PDFExport
            forcePageBreak=".page-break"
            paperSize={'A4'}
            margin="1.5cm"
            scale={0.7}
            fileName={'Survey-Response-List.pdf'}
            ref={inputEl}
          > */}
            <div ref={ref} className="table text-center mb-4">

              <table className="table table-Common table-bordered table-striped" >


                <thead>
                  <tr className="bg-secondary text-white">
                    <th>S No.</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Mobile', isAsending ? 'desc' : 'asc')}>Mobile</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Partner', isAsending ? 'desc' : 'asc')}>Partner</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Channel', isAsending ? 'desc' : 'asc')}>Channel</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Send Status', isAsending ? 'desc' : 'asc')} >Send Status</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Survey Response<', isAsending ? 'desc' : 'asc')} className="table-td">Survey Response</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Survey Response Datetime', isAsending ? 'desc' : 'asc')} className="table-td">Survey Response Datetime</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Survey Send Date Time', isAsending ? 'desc' : 'asc')} className="table-td">Survey Send Date Time</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td scope="row"></td>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control search-filed" value={searchMobile} onChange={(e) => setSearchMobile(e.target.value)} placeholder="Search.." />
                        <i className="input-group-text pt-2 fa fa-search search-icon" aria-hidden="true" />
                        <div className="input-group-append">
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control search-filed" value={searchPartner} onChange={(e) => setSearchPartner(e.target.value)} placeholder="Search.." />
                        <i className="input-group-text pt-2 fa fa-search search-icon" aria-hidden="true" />
                        <div className="input-group-append">
                        </div>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div className>
                        <select value={sendStatus} onChange={(e) => setSendStatus(e.target.value)} className="form-control">
                          <option value="">Select status</option>
                          <option value="Sent">Sent</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className>
                        <select value={surveyResponse} onChange={(e) => setSurveyResponse(e.target.value)} className="form-control">
                          <option value="">Select survey response</option>
                          <option value="Received">Received</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  {surveyResponseList && surveyResponseList.map((data, index) => (
                    <tr key={index}>
                      <td className="table-td-1" scope="row">{firstIndexItem + index}</td>
                      <td >{data.Mobile}</td>
                      <td >{data.Partner}</td>
                      <td >{data.Channel}</td>
                      <td >{data["Send Status"]}</td>
                      <td >{data["Survey Response"]}</td>
                      <td >{data["Survey Response Datetime"]}</td>
                      <td >{data["Survey Send Date Time"]}</td>
                    </tr>
                  ))}
                </tbody>



              </table>
              <div className="text-center d-flex justify-content-between align-items-center"  >
                <Pagination count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="primary" align="center" />
                <h5 className="float-left">{firstIndexItem} - {totalData} of {totalItem} items</h5>
              </div>


            </div>
          {/* </PDFExport> */}
        </>
      }
    </div >
    <DumpDataList Id={Id}/>
    </>
  );
}

export default ListViewTable;
import React, { useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;

const TableCity = (props) => {
  const{cityArr,selectedCity,onChange} =props;
const[show,setShow] = useState(false)
const[valueData,setValueData] = useState([])
   
  
const handleChange = (value) => {
  onChange(value,'city')
}
    
   

   


    return ( 
     
          <div className="col-lg-3 mt-4 px-1 ">
          <Select value={selectedCity} placeholder="select city" mode="multiple" onChange={handleChange} style={{width:'100%'}}>
          {cityArr.map((data,index) => (
            <Option key={index} value={data.city}>{data.city}</Option>
          ))}
          </Select>
          </div>
     );
}
 
export default TableCity;
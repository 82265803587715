import React, { useState, useEffect } from 'react'
import CategoriesDepenciesChart from '../../charts/categoriesDependenciesChart'
import WordCloudChart from '../../charts/wordCloudChart'
import Skeleton from 'react-loading-skeleton';

function CategoriesDepenciesAndWordCloud(props){
    
    const [selectChart,setSelectChart] = useState('All')
    const [skeletonLoader, setSkeletonLoader] = useState([false])
    
    const {catSubCategoriesResultData} = props;
 
    const onChangeClick = async(e)=>{
        setSkeletonLoader(true)
        await setSelectChart(e.target.name)
    
        setSkeletonLoader(false)

    }
   

    return(
        <div className="col-md-12 mt-3">
        <div className="row">
            <div className="col-md-12">
                <div className="card bg-white left-right">
                    <div className="card-header d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <h5 className="primary-cl"> <b>Figure out from where the actual problem starts - Dependency Graph between Products, Main Categories & Sub – Categories</b>
                        </h5>
                    </div>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 align-center wordCloud3">
                            <div className="wordCloud3" align="center">
                            <CategoriesDepenciesChart className="highchartOverflow" catSubCategoriesResultData={catSubCategoriesResultData}/>
                            <div  className="wordCloud4" align="center">
                            <p  className="wordCloud5">This graph represents dependency of particular products w.r.t their main-categories and sub-categories. Click on node or branch to view respective data set.
                                 </p>
                                 </div>
                            </div>
                        </div>
                
                    </div>
                    
                    </div>
                   
                </div>
                <div className="card bg-white left-right">
                <div className="card-header d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <h5 className="primary-cl"> <b>Keywords Customers are Using Mostly During Suggestions -  Word Cloud</b>
                        </h5>
                    </div>
                    <div>
                                <button onClick={onChangeClick} name="All"className={selectChart == "All" ?"btn btn-primary active":"btn btn-primary"}>All</button>
                                <button onClick={onChangeClick}  name="Promoter" className={selectChart== "Promoter" ?"btn btn-primary active":"btn btn-primary"}>Promoter</button>
                                <button onClick={onChangeClick}  name="Passive" className={selectChart== "Passive" ?"btn btn-primary active":"btn btn-primary"}>Passive</button>
                                <button onClick={onChangeClick}  name="Detractor" className={selectChart== "Detractor" ?"btn btn-primary active":"btn btn-primary"}>Detractor</button>
                            </div>
                    </div>

                <div className="card-body">
                    <div className="col-md-12">
                    <div className="wordCloud1"  align="center">
                        
                        
                        {skeletonLoader == true ? 
                        <Skeleton count={25}/>
                        : <>
                        <WordCloudChart Name = {selectChart}/>
                        </>}
                           
                           <p className="wordCloud2" >This graph represents mostly used keywords during customer suggestions. Click on keyword to view it occurrences and respective data set.
                                 </p>
                           </div>
                        </div>
                    </div>
                </div>

                
            </div>
            </div>
        
        </div>
    )
}
export default CategoriesDepenciesAndWordCloud
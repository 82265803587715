import React, { Component, Fragment, useEffect, useState, useRef, createRef } from 'react'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash';
import AllPartnerWiseListTable from '../../common/allPartnerWiseListTable';
import DemoChart from '../charts/demoChart';
import ProductWiseChat from '../charts/productWiseChat';
import { dateFilter, lastMonth, currentMonth, currentDay } from '../../utils/common';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import DateRangePicker from '../../common/dateRangePicker';
import MtdCommentChart from '../charts/MtdCommentChart';
import CustomCalendar from '../../common/customCalender';
import CalendarLineChart from '../charts/calendarLineChart';
import { CSVLink, CSVDownload } from "react-csv";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import { PDFExport } from '@progress/kendo-react-pdf';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


// import './common/datePickerJquery'

const NpsBudsReport = (props) => {

  const api = new API()
  const csvLink = useRef()
  const inputEl = useRef(null);
  const inputEl1 = useRef(null);
  const inputEl2 = useRef(null);
  const inputEl3 = useRef(null);
  const inputEl4 = useRef(null);
  const ref = createRef();
  const ref1 = createRef();
  const ref2 = createRef();
  const ref3 = createRef();
  const ref4 = createRef();
  const [mtdResultData, setMtdResultData] = useState([])
  const [upiMtd, setUpiMtd] = useState([])
  const [fastagMtd, setFastagMtd] = useState([])
  const [rupayMtd, setRupayMtd] = useState([])
  const [netcMtd, setNetcMtd] = useState([])
  const [bhimMtd, setBhimMtd] = useState([])
  const [impsMtd, setImpsMtd] = useState([])

  const [upiDailyMtd, setUpiDailyMtd] = useState([])
  const [fastagDailyMtd, setFastagDailyMtd] = useState([])
  const [rupayDailyMtd, setRupayDailyMtd] = useState([])
  const [bhimDailyMtd, setBhimDailyMtd] = useState([])
  const [impsDailyMtd, setImpsDailyMtd] = useState([])


  // daily-calendar-mtd //
  const [upiDailyCalendarMtd, setUpiDailyCalendarMtd] = useState([])
  const [fastagDailyCalendarMtd, setFastagDailyCalendarMtd] = useState([])
  const [rupayDailyCalendarMtd, setRupayDailyCalendarMtd] = useState([])
  const [bhimDailyCalendarMtd, setBhimDailyCalendarMtd] = useState([])
  const [impsDailyCalendarMtd, setImpsDailyCalendarMtd] = useState([])

  const [loader, setLoader] = useState(true)
  const [startDate, setStartDate] = useState(currentMonth('start_date'))
  const [endDate, setEndDate] = useState(currentMonth('end_date'))

  const [startDateOther, setStartDateOther] = useState(currentMonth('start_date'))
  const [endDateOther, setEndDateOther] = useState(currentMonth('end_date'))
  const [partnerList, setPartnerList] = useState([])
  const [upiPartnerList, setUpiPartnerList] = useState('')
  const [partnerListRupay, setPartnerListRupay] = useState([])
  const [rupayPartnerList, setRupayPartnerList] = useState('')
  const [partnerListFastag, setPartnerFastag] = useState([])
  const [fastagPartnerList, setFastagPartnerList] = useState('')
  const [partnerListBhim, setPartnerListBhim] = useState([])
  const [bhimPartnerList, setBhimPartnerList] = useState('')
  const [partnerListImps, setPartnerListImps] = useState([])
  const [impsPartnerList, setImpsPartnerList] = useState('')
  const [more, setMore] = useState(false);
  const [more1, setMore1] = useState(false);
  const [more2, setMore2] = useState(false);
  const [more3, setMore3] = useState(false);
  const [more4, setMore4] = useState(false);




  useEffect(() => {
    ReportPartnerListCommonResponse()
    ReportPartnerListRupayCommonResponse()
    ReportPartnerListFastagCommonResponse()
    ReportPartnerListBhimCommonResponse()
    ReportPartnerListImpsCommonResponse()
    MtdUpiDailyResponse()
    MtdRupayDailyResponse()
    MtdFastagDailyResponse()
    MtdBhimDailyResponse()
    MtdImpsDailyResponse()
    MtdResponse()
    MtdUpiResponse()
    MtdRupayResponse()
    MtdFastagResponse()
    MtdBhimResponse()
    MtdImpsResponse()
    MtdUpiDailyCalendarResponse()
    MtdRupayDailyCalendarResponse()
    MtdFastagDailyCalendarResponse()
    MtdBhimDailyCalendarResponse()
    MtdImpsDailyCalendarResponse()
  }, [])


  useEffect(() => {
    MtdUpiResponse()
    MtdResponse()
    MtdRupayResponse()
    MtdFastagResponse()
    MtdBhimResponse()
    MtdImpsResponse()
    MtdUpiDailyResponse()
    MtdRupayDailyResponse()
    MtdFastagDailyResponse()
    MtdBhimDailyResponse()
    MtdImpsDailyResponse()
    

  }, [startDate, endDate, startDateOther, endDateOther, upiPartnerList, rupayPartnerList,impsPartnerList, bhimPartnerList, fastagPartnerList])

  const MtdResponse = async () => {

    let mtdResult = await api.post(config.DashboardProductMtd, { start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther })
    let mtdResultData = get(mtdResult, "data", [])
    console.log("mtdResultData=>",mtdResultData);
    setLoader(false)
    setMtdResultData(mtdResultData)
  }

  // separate MTD For All product List //
  const MtdUpiResponse = async () => {
    let dataObj = {
      products: '[8]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: upiPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdUpiResult = await api.post(config.DashboardProductMtd, dataObj)
    let mtdUpiResultData = get(mtdUpiResult, "data", [])
    setLoader(false)
    setUpiMtd(mtdUpiResultData)
  }
  const MtdRupayResponse = async () => {
    let dataRupayObj = {
      products: '[2]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: rupayPartnerList
    }
    if (dataRupayObj.partner_id === "") {
      delete dataRupayObj.partner_id
    }
    let mtdRupayResult = await api.post(config.DashboardProductMtd, dataRupayObj)
    let mtdRupayResultData = get(mtdRupayResult, "data", [])
    console.log(mtdRupayResultData, "mtdRupayResultData");
    setLoader(false)
    setRupayMtd(mtdRupayResultData)
  }
  const MtdFastagResponse = async () => {
    let dataFastagObj = {
      products: '[3]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: fastagPartnerList
    }
    if (dataFastagObj.partner_id === "") {
      delete dataFastagObj.partner_id
    }
    let mtdFastagResult = await api.post(config.DashboardProductMtd, dataFastagObj)
    let mtdFastagResultData = get(mtdFastagResult, "data", [])
    setLoader(false)
    setFastagMtd(mtdFastagResultData)
  }
  const MtdBhimResponse = async () => {
    let dataBhimObj = {
      products: '[9]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: bhimPartnerList
    }
    if (dataBhimObj.partner_id === "") {
      delete dataBhimObj.partner_id
    }
    let mtdBhimResult = await api.post(config.DashboardProductMtd, dataBhimObj)
    let mtdBhimResultData = get(mtdBhimResult, "data", [])
    console.log(mtdBhimResultData, "mtdBhimResultData");
    setLoader(false)
    setBhimMtd(mtdBhimResultData)
  }
  const MtdImpsResponse = async () => {
    let dataImpsObj = {
      products: '[6]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: impsPartnerList
    }
    if (dataImpsObj.partner_id === "") {
      delete dataImpsObj.partner_id
    }
    let mtdImpsResult = await api.post(config.DashboardProductMtd, dataImpsObj)
    let mtdImpsResultData = get(mtdImpsResult, "data", [])
    setLoader(false)
    setImpsMtd(mtdImpsResultData)
  }


  // separate partner List //
  const ReportPartnerListCommonResponse = async () => {

    let ReportPartnerListResult = await api.post(config.ReportPartnerListCommon, { product: "UPI", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultDataPartner = get(ReportPartnerListResult, "data", [])
    setLoader(false)
    setPartnerList(mtdResultDataPartner)
  }
  const ReportPartnerListRupayCommonResponse = async () => {

    let ReportPartnerListRupayResult = await api.post(config.ReportPartnerListCommon, { product: "RUPAY", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultRupayData = get(ReportPartnerListRupayResult, "data", [])
    setLoader(false)
    setPartnerListRupay(mtdResultRupayData)
  }
  const ReportPartnerListFastagCommonResponse = async () => {

    let ReportPartnerListFastagResult = await api.post(config.ReportPartnerListCommon, { product: "FASTAG", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultFastagData = get(ReportPartnerListFastagResult, "data", [])
    setLoader(false)
    setPartnerFastag(mtdResultFastagData)
  }
  const ReportPartnerListBhimCommonResponse = async () => {

    let ReportPartnerListBhimResult = await api.post(config.ReportPartnerListCommon, { product: "BHIM", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultBhimData = get(ReportPartnerListBhimResult, "data", [])
    setLoader(false)
    setPartnerListBhim(mtdResultBhimData)
  }
  const ReportPartnerListImpsCommonResponse = async () => {

    let ReportPartnerListImpsResult = await api.post(config.ReportPartnerListCommon, { product: "IMPS", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultImpsData = get(ReportPartnerListImpsResult, "data", [])
    setLoader(false)
    setPartnerListImps(mtdResultImpsData)
  }
  // separate MTD For Daily All product List //
  const MtdUpiDailyResponse = async () => {
    let dataObj = {
      products: '[8]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: upiPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdUpiDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataObj)
    let mtdUpiResultDailyData = get(mtdUpiDailyResult, "data", [])
    setLoader(false)
    setUpiDailyMtd(mtdUpiResultDailyData)
  }
  const MtdRupayDailyResponse = async () => {
    let dataRupayObj = {
      products: '[2]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: rupayPartnerList
    }
    if (dataRupayObj.partner_id === "") {
      delete dataRupayObj.partner_id
    }
    let mtdRupayDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataRupayObj)
    let mtdRupayResultDailyData = get(mtdRupayDailyResult, "data", [])
    console.log(mtdRupayResultDailyData, "mtdRupayResultData");
    setLoader(false)
    setRupayDailyMtd(mtdRupayResultDailyData)
  }
  const MtdFastagDailyResponse = async () => {
    let dataFastagObj = {
      products: '[3]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: fastagPartnerList
    }
    if (dataFastagObj.partner_id === "") {
      delete dataFastagObj.partner_id
    }
    let mtdFastagDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataFastagObj)
    let mtdFastagResultDailyData = get(mtdFastagDailyResult, "data", [])
    console.log(mtdFastagResultDailyData, "mtdFastagResultData");
    setLoader(false)
    setFastagDailyMtd(mtdFastagResultDailyData)
  }
  const MtdBhimDailyResponse = async () => {
    let dataBhimObj = {
      products: '[9]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: bhimPartnerList
    }
    if (dataBhimObj.partner_id === "") {
      delete dataBhimObj.partner_id
    }
    let mtdBhimDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataBhimObj)
    let mtdBhimResultDailyData = get(mtdBhimDailyResult, "data", [])
    console.log(mtdBhimResultDailyData, "mtdBhimResultData");
    setLoader(false)
    setBhimDailyMtd(mtdBhimResultDailyData)
  }

  const MtdImpsDailyResponse = async () => {
    let dataImpsObj = {
      products: '[6]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: impsPartnerList
    }
    if (dataImpsObj.partner_id === "") {
      delete dataImpsObj.partner_id
    }
    let mtdImpsDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataImpsObj)
    let mtdImpsResultDailyData = get(mtdImpsDailyResult, "data", [])
    console.log(mtdImpsResultDailyData, "mtdImpsResultData");
    setLoader(false)
    setImpsDailyMtd(mtdImpsResultDailyData)
  }
  
  // dailyMtdCalender //
  const MtdUpiDailyCalendarResponse = async () => {
    let dataObj = {
      products: '[8]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: upiPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdUpiDailyCalendarResult = await api.post(config.ReportDailyMtdCalendar, dataObj)
    let mtdUpiResultDailyCalendarData = get(mtdUpiDailyCalendarResult, "data", {})
    setLoader(false)
    setUpiDailyCalendarMtd(mtdUpiResultDailyCalendarData)
  }

  const MtdRupayDailyCalendarResponse = async () => {
    let dataObj = {
      products: '[2]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: rupayPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdRupayDailyCalendarResult = await api.post(config.ReportDailyMtdCalendar, dataObj)
    let mtdRupayResultDailyCalendarData = get(mtdRupayDailyCalendarResult, "data", {})
    setLoader(false)
    setRupayDailyCalendarMtd(mtdRupayResultDailyCalendarData)
  }
  const MtdFastagDailyCalendarResponse = async () => {
    let dataObj = {
      products: '[3]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: fastagPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdFastagDailyCalendarResult = await api.post(config.ReportDailyMtdCalendar, dataObj)
    let mtdFastagResultDailyCalendarData = get(mtdFastagDailyCalendarResult, "data", {})
    setLoader(false)
    setFastagDailyCalendarMtd(mtdFastagResultDailyCalendarData)
  }
  const MtdBhimDailyCalendarResponse = async () => {
    let dataObj = {
      products: '[9]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: bhimPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdBhimDailyCalendarResult = await api.post(config.ReportDailyMtdCalendar, dataObj)
    let mtdBhimResultDailyCalendarData = get(mtdBhimDailyCalendarResult, "data", {})
    setLoader(false)
    setBhimDailyCalendarMtd(mtdBhimResultDailyCalendarData)
  }

  const MtdImpsDailyCalendarResponse = async () => {
    let dataObj = {
      products: '[6]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: impsPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdImpsDailyCalendarResult = await api.post(config.ReportDailyMtdCalendar, dataObj)
    let mtdImpsResultDailyCalendarData = get(mtdImpsDailyCalendarResult, "data", {})
    setLoader(false)
    setImpsDailyCalendarMtd(mtdImpsResultDailyCalendarData)
  }

  const onButtonPdfClick = () => {
    inputEl.current.save();
  };

  const onButtonPdfClickRupay = () => {
    inputEl1.current.save();
  };
  const onButtonPdfClickBhim = () => {
    inputEl3.current.save();
  };
  const onButtonPdfClickFastag = () => {
    inputEl2.current.save();
  };
  const onButtonPdfClickImps = () => {
    inputEl4.current.save();
  };

  return (
    <Fragment>
      <div className="card container">


        <div className="card-body">
          {loader == true ?
            <Skeleton count={15} /> :
            <>
              <div className="mb-3">
                <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate} />
              </div>

              {/* upi mtd */}
              {upiMtd.map((mtdData, mtdIndex) => (
                <div className="row">

                  <div key={mtdIndex} className="col-md-12">
                    <div className="card-header mtd-header mb-4 mt-2">
                      <h5 className="upiQuesAndRes2 mtd-img d-flex align-items-center justify-content-between mb-0">
                        {mtdData.name === "UPI (NEW SURVEY)" ? <img className="productUpiBasicDetails3" src="img/upi.png" /> : ""}
                        <div className="">
                          <select onChange={(e) => setUpiPartnerList(e.target.value)} className="form-control" value={upiPartnerList} name="upiPartnerList">
                            <option value="">All</option>
                            {partnerList.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                          </select>
                        </div>
                      </h5>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                      </div>


                    </div>
                  </div>
                </div>
              ))}
              {/* bhim mtd */}
              {bhimMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header  mtd-header mb-4 mt-2">
                    <h5 className="upiQuesAndRes2 mtd-img d-flex align-items-center justify-content-between mb-0 ">
                      {mtdData.name === "BHIM (NEW SURVEY VERSION2)" ? <img className="productUpiBasicDetails3" src="img/bhim-color.png" /> : ""}
                      <div className="">
                        <select onChange={(e) => setBhimPartnerList(e.target.value)} className="form-control" value={bhimPartnerList} name="bhimPartnerList">
                          <option value="">All</option>
                          {partnerListBhim.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
              ))}
              {/* rupay mtd */}
              {rupayMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header mtd-header mb-4 mt-2">
                    <h5 className="upiQuesAndRes2 mtd-img d-flex align-items-center justify-content-between mb-0 ">
                      {mtdData.name === "RUPAY" ? <img className="productUpiBasicDetails3" src="img/rupay.png" /> : ""}
                      <div className="">
                        <select onChange={(e) => setRupayPartnerList(e.target.value)} className="form-control" value={rupayPartnerList} name="upipartnerList">
                          <option value="">All</option>
                          {partnerListRupay.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
              ))}

              {/* fastag mtd */}
              {fastagMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header mtd-header mb-4 mt-2">
                    <h5 className="upiQuesAndRes2  mtd-img d-flex align-items-center justify-content-between mb-0 ">
                      {mtdData.name === "FASTAG" ? <img className="productUpiBasicDetails3" src="img/netc.png" /> : ""}
                      <div className="">
                        <select onChange={(e) => setFastagPartnerList(e.target.value)} className="form-control" value={fastagPartnerList} name="upipartnerList">
                          <option value="">All</option>
                          {partnerListFastag.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
              ))}

               {/* imps mtd */}
               {impsMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header mtd-header mb-4 mt-2">
                    <h5 className="upiQuesAndRes2  mtd-img d-flex align-items-center justify-content-between mb-0 ">
                      {mtdData.name === "IMPS" ?
                      // <h4 className="productUpiBasicDetails3">IMPS</h4>
                       <img className="productUpiBasicDetails3" src="img/imps-fastag.png" />
                      : ""}
                      <div className="">
                        <select onChange={(e) => setImpsPartnerList(e.target.value)} className="form-control" value={impsPartnerList} name="impspartnerList">
                          <option value="">All</option>
                          {partnerListImps.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
                ))}


            </>
          }
        </div>
      </div>
     
      {/* upi */}
      <PDFExport
        forcePageBreak=".page-break"
        paperSize={'A2'}
        margin="1.5cm"
        scale={0.7}
        fileName={'Mtd-upi-report.pdf'}
        ref={inputEl}
      >
        <div className="card container">
        {upiDailyMtd.map((mtdData1,) => (
              <div className="card-header mtd-header  mt-3">
                <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                  {mtdData1.name === "UPI (NEW SURVEY)" ? <img className="productUpiBasicDetails3 mtd-img d-block mx-auto" src="img/upi.png" /> : ""}
                  <ButtonDropdown isOpen={more} toggle={() => setMore(!more)}>
                    <DropdownToggle className="btn white-btn1">
                      <i className="fa  fa-bars ml-2 btn color-white" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        {/* <CSVLink color="black" data={responseDownloadData}
                    filename={"Mtd-report.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink> */}
                      </DropdownItem>
                      <DropdownItem>
                        <span onClick={onButtonPdfClick}>Generate Pdf</span>

                      </DropdownItem>
                      <DropdownItem filename={"Mtd-report.jpg"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                      <DropdownItem filename={"Mtd-report.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </h5>

              </div>
            ))}
          <div ref={ref}  className="card-body">
           
            <div className="row">
              <div className="col-lg-12">
                {/* <CustomCalendar/> */}
                <CalendarLineChart Names={"UPI"} DailyCalendarMtd={upiDailyCalendarMtd} />
              </div>

              <div className="col-lg-12">

                {upiDailyMtd.map((mtdData, mtdIndex) => (

                  <DemoChart
                    DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                    Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])}
                  />
                ))}
              </div>

            </div>
          </div>
        </div>
      </PDFExport>
      {/* rupay */}
      <PDFExport
        forcePageBreak=".page-break"
        paperSize={'A2'}
        margin="1.5cm"
        scale={0.7}
        fileName={'Mtd-rupay-report.pdf'}
        ref={inputEl1}
      >
        <div  className="card container">
          
        {rupayDailyMtd.map((mtdData1,) => (
        <div className="card-header mtd-header mt-3">
                <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                  {mtdData1.name === "RUPAY" ? <img className="productUpiBasicDetails3 mtd-img d-block mx-auto" src="img/rupay.png" /> : ""}
                  <ButtonDropdown isOpen={more1} toggle={() => setMore1(!more1)}>
                    <DropdownToggle className="btn white-btn1">
                      <i className="fa  fa-bars ml-2 btn color-white" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                      </DropdownItem>
                      <DropdownItem>
                        <span onClick={onButtonPdfClickRupay}>Generate Pdf</span>

                      </DropdownItem>
                      <DropdownItem filename={"Mtd-report.jpg"} onClick={() => exportComponentAsJPEG(ref1)}>JPEG Download</DropdownItem>
                      <DropdownItem filename={"Mtd-report.png"} onClick={() => exportComponentAsPNG(ref1)}>PNG Download</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>

                </h5>

              </div>
            ))}
          <div ref={ref1} className="card-body">
            
           
            <div className="row">
              <div className="col-lg-12">
                {/* <CustomCalendar/> */}
                <CalendarLineChart Names={"RUPAY"} DailyCalendarMtd={rupayDailyCalendarMtd} />
              </div>

              <div className="col-lg-12">

                {rupayDailyMtd.map((mtdData, mtdIndex) => (

                  <DemoChart
                    DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                    Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])}
                  />
                ))}
              </div>

            </div>
          </div>
        </div>
      </PDFExport>
      {/* fastag */}
      <PDFExport
        forcePageBreak=".page-break"
        paperSize={'A2'}
        margin="1.5cm"
        scale={0.7}
        fileName={'Mtd-fastag-report.pdf'}
        ref={inputEl2}
      >
        <div  className="card container">
        {fastagDailyMtd.map((mtdData1,) => (
              <div className="card-header mtd-header  mt-3">
                <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                  {mtdData1.name === "FASTAG" ? <img className="productUpiBasicDetails3 mtd-img d-block mx-auto" src="img/netc.png" /> : ""}
                  <ButtonDropdown isOpen={more2} toggle={() => setMore2(!more2)}>
                    <DropdownToggle className="btn white-btn1">
                      <i className="fa  fa-bars ml-2 btn color-white" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        {/* <CSVLink color="black" data={responseDownloadData}
                    filename={"Mtd-report.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink> */}
                      </DropdownItem>
                      <DropdownItem>
                        <span onClick={onButtonPdfClickFastag}>Generate Pdf</span>

                      </DropdownItem>
                      <DropdownItem filename={"Mtd-fastag-report.jpg"} onClick={() => exportComponentAsJPEG(ref2)}>JPEG Download</DropdownItem>
                      <DropdownItem filename={"Mtd-fastag-report.png"} onClick={() => exportComponentAsPNG(ref2)}>PNG Download</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>

                </h5>

              </div>
            ))}
          <div ref={ref2} className="card-body">
         
            <div className="row">
              <div className="col-lg-12">
                {/* <CustomCalendar/> */}
                <CalendarLineChart Names={"FASTAG"} DailyCalendarMtd={fastagDailyCalendarMtd} />
              </div>

              <div className="col-lg-12">

                {fastagDailyMtd.map((mtdData, mtdIndex) => (

                  <DemoChart
                    DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                    Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])}
                  />
                ))}
              </div>

            </div>
          </div>
        </div>
      </PDFExport>
      {/* bhim */}
      <PDFExport
        forcePageBreak=".page-break"
        paperSize={'A2'}
        margin="1.5cm"
        scale={0.7}
        fileName={'Mtd-bhim-report.pdf'}
        ref={inputEl3}
      >
        <div  className="card container">
        {bhimDailyMtd.map((mtdData1,) => (
              <div className="card-header mtd-header mt-3">
                <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                  {mtdData1.name === "BHIM (NEW SURVEY VERSION2)" ? <img className="productUpiBasicDetails3 mtd-img d-block mx-auto" src="img/bhim-color.png" /> : ""}
                  <ButtonDropdown isOpen={more3} toggle={() => setMore3(!more3)}>
                    <DropdownToggle className="btn white-btn1">
                      <i className="fa  fa-bars ml-2 btn color-white" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        {/* <CSVLink color="black" data={responseDownloadData}
                    filename={"Mtd-report.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink> */}
                      </DropdownItem>
                      <DropdownItem>
                        <span onClick={onButtonPdfClickBhim}>Generate Pdf</span>

                      </DropdownItem>
                      <DropdownItem filename={"Mtd-bhim-report.jpg"} onClick={() => exportComponentAsJPEG(ref3)}>JPEG Download</DropdownItem>
                      <DropdownItem filename={"Mtd-bhim-report.png"} onClick={() => exportComponentAsPNG(ref3)}>PNG Download</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>

                </h5>

              </div>
            ))}
          <div ref={ref3} className="card-body">
          
            <div className="row">
              <div className="col-lg-12">
                {/* <CustomCalendar/> */}
                <CalendarLineChart Names={"BHIM"} DailyCalendarMtd={bhimDailyCalendarMtd} />
              </div>

              <div className="col-lg-12">

                {bhimDailyMtd.map((mtdData, mtdIndex) => (

                  <DemoChart
                    DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                    Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])}
                  />
                ))}
              </div>

            </div>
          </div>
        </div>
      </PDFExport>
      {/* Imps */}
      <PDFExport
              forcePageBreak=".page-break"
              paperSize={'A2'}
              margin="1.5cm"
              scale={0.7}
              fileName={'Mtd-Imps-report.pdf'}
              ref={inputEl3}
            >
              <div  className="card container">
              {impsDailyMtd.map((mtdData1,) => (
                    <div className="card-header mtd-header mt-3">
                      <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                        {mtdData1.name === "IMPS" ? 
                        <h4 className="productUpiBasicDetails3 mtd-img d-block mx-auto">IMPS</h4>
                        // <img className="productUpiBasicDetails3 mtd-img d-block mx-auto" src="img/bhim-color.png" /> 
                        : ""}
                        <ButtonDropdown isOpen={more4} toggle={() => setMore4(!more4)}>
                          <DropdownToggle className="btn white-btn1">
                            <i className="fa  fa-bars ml-2 btn color-white" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              {/* <CSVLink color="black" data={responseDownloadData}
                          filename={"Mtd-report.csv"} ref={csvLink}
                        >Excel Download
                    </CSVLink> */}
                            </DropdownItem>
                            <DropdownItem>
                              <span onClick={onButtonPdfClickImps}>Generate Pdf</span>

                            </DropdownItem>
                            <DropdownItem filename={"Mtd-Imps-report.jpg"} onClick={() => exportComponentAsJPEG(ref3)}>JPEG Download</DropdownItem>
                            <DropdownItem filename={"Mtd-Imps-report.png"} onClick={() => exportComponentAsPNG(ref3)}>PNG Download</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>

                      </h5>

                    </div>
                  ))}
                <div ref={ref4} className="card-body">
                
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <CustomCalendar/> */}
                      <CalendarLineChart Names={"IMPS"} DailyCalendarMtd={impsDailyCalendarMtd} />
                    </div>

                    <div className="col-lg-12">

                      {impsDailyMtd.map((mtdData, mtdIndex) => (

                        <DemoChart
                          DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])}
                        />
                      ))}
                    </div>

                  </div>
                </div>
              </div>
            </PDFExport>

    </Fragment>

  )
}

export default NpsBudsReport 

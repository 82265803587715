import { get } from 'lodash';
import React, { useState, useEffect } from 'react'
import ApplicationUsageChart from '../../charts/applicationUsageChart'
import NpsScoreChart from '../../charts/npsScoreChart'
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
import { Loader } from '../../../common/loader'
import Skeleton from 'react-loading-skeleton';

function ApplicationUsageComparision(props) {
    const api = new API()
    const { applicationUsage } = props;
    const [selectChart, setSelectChart] = useState('All')
    const [skeletonLoader, setSkeletonLoader] = useState([false])
    const [AndroidIos, setAndroidIos] = useState([])




    // AppUseComparision //
    const [appUseComparisionResultData, setAppUseComparisionResultData] = useState([])

    const appUseComparision = async () => {
        let appUseComparisionResult = await api.get(config.dashboardAppUseComparision, selectChart === 'All' ? false : true, { cat: selectChart });
        let appUseComparisionResultData = await appUseComparisionResult.data
        setAppUseComparisionResultData(appUseComparisionResultData)
        setSkeletonLoader(false)
    }

    const androidIos = async () => {
        let androidIosScore = await api.get(config.dashboardAndroidIos)
        let androidIosScoreData = get(androidIosScore, 'data', '')
        setAndroidIos(androidIosScoreData)
    }




    useEffect(() => {
        appUseComparision()
        androidIos()
    }, [selectChart])
    const onChangeClick = (e) => {
        // setSkeletonLoader(true)
        setSelectChart(e.target.name)
    }

    return (

        <div className="col-md-12 mt-3">
            <div className="row">
                <div className="col-md-6">
                    <div className="card bg-white left-right">
                        <div className="card-header d-flex justify-content-end">

                            <div>

                                <button onClick={onChangeClick} name="All" className={selectChart == "All" ? "btn btn-primary active" : "btn btn-primary"}>All</button>
                                <button onClick={onChangeClick} name="payer" className={selectChart == "payer" ? "btn btn-primary active" : "btn btn-primary"}>Payer App</button>
                                <button onClick={onChangeClick} name="receiver" className={selectChart == "receiver" ? "btn btn-primary active" : "btn btn-primary"}>Receiver App</button>

                            </div>
                        </div>

                        {skeletonLoader == true ?
                            <div className="container ApplicationUsageComparision1">
                                <Skeleton count={15} />
                            </div>
                            : <>
                                {appUseComparisionResultData <= 0 ? <>
                                    <Loader />
                                </> : <>
                                        <div className="card-body ApplicationUsageComparision2">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {/* <h5 align="left"><b>Know Where BHIM Application Standing in Market</b></h5> */}
                                                    <ApplicationUsageChart Name={selectChart} appUseComparisionResultData={appUseComparisionResultData} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                            </>}
                    </div>
                </div>

                <div className="col-md-6" >
                    <div className="card  bg-white left-right">
                        <div className="card-header">

                            <h5 className="stateWiseImpactNps1"><b>Device Wise NPS Score</b></h5>
                        </div>
                        {appUseComparisionResultData <= 0 ? <>
                            <Loader />
                        </> : <>
                                <div className="card-body ApplicationUsageComparision2">


                                    <div className="col-md-12 mb-2">
                                        <div className=" bg-white">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12">
                                                            <div className="chart-container">
                                                                {/* {alert(JSON.stringify(AndroidIos))} */}
                                                                <NpsScoreChart className="ApplicationUsageComparision3" applicationUsageTitle={get(AndroidIos, 'Android.title', '')} applicationUsageScore={get(AndroidIos, 'Android.nps_score', '')} />
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12">
                                                            <div className="chart-container">
                                                                <NpsScoreChart className="ApplicationUsageComparision3" applicationUsageTitle={get(AndroidIos, 'iOS.title', '')} applicationUsageScore={get(AndroidIos, 'iOS.nps_score', '')} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="col-md-12 mb-2">
                        <div className="card-box bg-white">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12">
                                            <div className="chart-container">
                                                <NpsScoreChart className="ApplicationUsageComparision3" applicationUsageTitle={get(applicationUsage, 'p2p.title', '')} applicationUsageScore={get(applicationUsage, 'p2p.nps_score', 0)} />
                                            </div>

                                        </div>
                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12">
                                            <div className="chart-container">
                                                <NpsScoreChart className="ApplicationUsageComparision3" applicationUsageTitle={get(applicationUsage, 'p2m.title', '')} applicationUsageScore={get(applicationUsage, 'p2m.nps_score', 0)} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApplicationUsageComparision
import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import WordCloud from "highcharts/modules/wordcloud.src.js"
import Exporting from "highcharts/modules/exporting.js"
import ExportData from "highcharts/modules/export-data.js"


ExportData(Highcharts);
Exporting(Highcharts);
WordCloud(Highcharts);

const ProductWiseChat = (props) => {
  const { Name, DailyCount,MonthDate,DateAndCount,MtdNpsScore,Count,NpsScore,partnerName} = props


 

  // useEffect(() => {

  //   MonthDate.forEach((itemDate,index)=>{
  //     MonthDate[index]=itemDate + " ("+DailyCount[index]+")";
  //   })
  // }, [])

  const options = {
chart: {
  type: 'column'
},
title: {
  text: `${Name} ${"|"} ${partnerName}`, 
},
credits: {
    enabled: false
},
xAxis:[ {
  
  title: {
    text: 'Date with daily counts'
  },
  type: 'category',
  categories: DateAndCount,
  labels: {
      rotation: -45,
      style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
      }
  }
}],
yAxis: {
  max:100,
  min: -100,
  title: {
      text: ''
  }
},
legend: {
  enabled: false
},
exporting: {
  enabled: false
},
tooltip: {
  shared: true
},
  series: [{
    colorByPoint: true,
      name: 'Daywise Nps score',
      data: NpsScore,//UpiMtd,
      
      dataLabels: {
          enabled: true,
          rotation: 0,
          color: 'black',
          align: 'right',
           format: '{point.y:.1f}', // one decimal
          y: 5, // 10 pixels down from the top
          style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  },
  
]
}

  return (
    <Fragment>

      <HighchartsReact 
        highcharts={Highcharts}
        options={options}
      />
 
    </Fragment>
  );
}

export default ProductWiseChat;
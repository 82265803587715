import React from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';



const DateRangePicker = (props) => {
  const { DateStart, DateEnd, rangeValue } = props;
  const { RangePicker } = DatePicker;

  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log(dates, dateStrings, 'dates, dateStrings');
      DateStart(dateStrings[0])
      DateEnd(dateStrings[1])
    } else {
      DateEnd('')
      DateStart('')
    }
  }

 const disabledDate = current => {
    return current && moment(current).add('days') > moment().endOf("day");
  };
  return (
    <Space className="mt-4 mb-4" direction="vertical" size={12}>
      <RangePicker
         disabledDate={disabledDate}
        // value={rangeValue}
        ranges={{
          Today: [moment(), moment()],
          Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
          'This Month': [moment().startOf('month'), moment()],
          'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
        }}
        onChange={onChange}
      />

    </Space>
  );
}

export default DateRangePicker;


import React, { useEffect, useState } from 'react';
import TablePartner from './tablePartner';
import TableZone from './tableZone';
import TableCity from './tableCity';
import TableState from './tableState';
import AllPartnerCityWiseTableDemo from '../../common/allPartnerCityWiseTableDemo';
import DateRangePicker from '../../common/dateRangePicker';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash';
import { Button } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

const TableCityStateZone = (props) => {


  const api = new API()
  const [showUpi, setShowUpi] = useState(false)
  const [surveyId, setSurveyId] = useState(null)
  const [showFastag, setShowFastag] = useState(false)
  const [showRupay, setShowRupay] = useState(false)
  const [showBhim, setShowBhim] = useState(false)
  const [valueData, setValueData] = useState([])
  const [selectedPartner, setSelectedPartner] = useState([])
  const [selectedState, setSelectedState] = useState([])
  const [selectedZone, setSelectedZone] = useState([])
  const [selectedCity, setSelectedCity] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [applyFilter, setApplyFilter] = useState(false)

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [surveyList, setSurveyList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)
  const [npsScoreList, setNpsScoreList] = useState([])
  const [npsScoreKey, setNpsScoreKey] = useState([])
  const [stateArr, setStateArr] = useState([])
  const [cityArr, setCityArr] = useState([])
  const [zoneArr, setZoneArr] = useState([])
  const [partnerArr, setPartnerArr] = useState([])
  const [itemPerPage, setItemPerPage] = useState(5)
  const [responseDownloadData, setResponseDownloadData] = useState([])


  useEffect(() => {
    surveyListResponse()

  }, [])

  useEffect(() => {

  },[startDate,endDate,selectedZone,selectedState,selectedPartner,selectedCity])

  const surveyListResponse = async (value = 1) => {
    let Data

    Data = { page_no: value }


    // if (page <= totalPage) {
    const result = await api.post(config.reportSurveyList, Data)
    let responseData = result && result.data && result.data.finalResult
    let surveyResponseData = result && result.data

    // console.log("history data is", result.data.doc.redeemPoint)
    setSurveyList(responseData)
    setLoader(false)
    // setTotalItem(get(surveyResponseData, "total_item", 0))
    // setTotalPage(surveyResponseData.total_page)
    // setTotalData(surveyResponseData.last_index)
    // setCurrentDataCount(surveyResponseData.current_page)
    // setFirstIndexItem(surveyResponseData.first_index)

    // }

  }


  const upiId = async (dataId, type,isReset=false, pageNo = page, itemPerPageCount = itemPerPage) => {
    let Data = {
      survey_id: dataId && dataId.Id,
      is_all: type===true ? 0 : 1,
      item_per_page: itemPerPageCount,
      page_no: pageNo, partner: isReset ? JSON.stringify([]) : JSON.stringify(selectedPartner),
      start_date: startDate, end_date: endDate, zone: isReset ? JSON.stringify([]) : JSON.stringify(selectedZone), city: isReset ? JSON.stringify([]) : JSON.stringify(selectedCity), state: isReset ? JSON.stringify([]) : JSON.stringify(selectedState)
    }


    if (Data.start_date === "") {
      delete Data.start_date
    }
    if (Data.end_date === "") {
      delete Data.end_date
    }
    // if (page <= totalPage) {

      const result = await api.post(config.reportPartnerCityWiseList, Data)
      if (result) {
        let responseData = result && result.data && result.data.finalResult
        let responseDataKey = result && result.data && result.data.finalResult && result.data.finalResult.keys
        let surveyResponseData = result && result.data
        setNpsScoreList(responseData)
        
        setNpsScoreKey(responseDataKey)
        setTotalItem(surveyResponseData.total_item)
        setTotalPage(surveyResponseData.total_page)
        setTotalData(surveyResponseData.last_index)
        setCurrentDataCount(surveyResponseData.current_page)
        setFirstIndexItem(surveyResponseData.first_index)
        partnerData(dataId)
        // upiIdDownload(dataId)
        setSurveyId(dataId)
        zoneData()
        setShowUpi(true)
      }

      setLoader(false)
    // }
  }

  const upiIdDownload = async (dataId, type,isReset=false, pageNo = page, itemPerPageCount = itemPerPage) => {
    console.log(isReset,'isReset')
    let Data = {
      survey_id: dataId && dataId.Id,
      is_all: type===true ? 0 : 1,
      item_per_page: itemPerPageCount,
      page_no: pageNo, partner:isReset ? JSON.stringify([]) : JSON.stringify(selectedPartner),
      start_date:startDate, end_date:endDate, zone:isReset ? JSON.stringify([]) : JSON.stringify(selectedZone),
      city:isReset ? JSON.stringify([]) : JSON.stringify(selectedCity), state:isReset ? JSON.stringify([]) : JSON.stringify(selectedState)

    }
    if (Data.start_date === "" || isReset) {
      delete Data.start_date
    }
    if (Data.end_date === "" || isReset) {
      delete Data.end_date
    }
    const result = await api.post(config.downloadCityWiseReport, Data)
    if (result) {
      let responseArr = get(result, "data", "finalResult", [])
      setResponseDownloadData(responseArr.finalResult)

    }
    setLoader(false)
  }


  const stateData = async (zone) => {
    const result = await api.post(config.getState, { zone: JSON.stringify(zone) })
    let responseData = result && result.data && result.data
    setStateArr(responseData)
  }
  const cityData = async (state) => {
    const result = await api.post(config.getCity, { state: JSON.stringify(state) })
    let responseData = result && result.data && result.data
    setCityArr(responseData)
  }

  const zoneData = async () => {
    const result = await api.post(config.getZone, true)
    let responseData = result && result.data && result.data
    setZoneArr(responseData)
  }

  const partnerData = async (dataId) => {
    let data = {
      survey_id: dataId.Id

    }
    const result = await api.post(config.ReportPartnerListCommon, data)
    let responseData = result && result.data && result.data
    setPartnerArr(responseData)
  }

  const handlePageChange = async (event, value) => {
    if (totalPage !== 0) {
      setPage(value);
      upiId(surveyId, applyFilter, false,value, itemPerPage)
      upiIdDownload(surveyId, applyFilter,false, value, itemPerPage)

    }
    setLoader(false)
  }
  const onChange = (value, key) => {
    switch (key) {
      case 'partner':
        setSelectedPartner(value)
        break;
      case 'zone':
        setSelectedZone(value)
        stateData(value)
        break;
      case 'state':
        setSelectedState(value)
        cityData(value)
        break;
      case 'city':
        setSelectedCity(value)
        break;
      default:
        break;
    }
  };

  const applyFilterFunc = () => {
    if (selectedPartner.length > 0 || selectedZone.length > 0 || selectedState.length > 0 || selectedCity.length > 0 || startDate !== "" || endDate !== "") {
      setApplyFilter(true)
      upiId(surveyId, true)
      upiIdDownload(surveyId, true)
    }else{
      setApplyFilter(false)
    }
  }

  const resetFilter = async() => {
    
    setStartDate('')
    setEndDate('')
    setSelectedZone([])
    setSelectedState([])
    setSelectedPartner([])
    setSelectedCity([])
    upiId(surveyId,false,true)
    upiIdDownload(surveyId,false,true)
    setApplyFilter(false)
       
  }
//   const   handleDateChange = (startDate, endDate,dateType) => {
//     this.setState({
//         startDate, endDate,dateType
//     })
// }
console.log(selectedPartner,selectedZone,selectedState,selectedCity,"sfghjkl");
  return (
    <div className=" App site-wrap container mt-5">
      <div className="bg-dark card-body py-3 "><h5 className="mb-0 text-white">CITY & STATE WISE SURVEY RESPONSE</h5></div>
      <div className="table text-center mb-4">


        <>

          <table className="table table-Common table-bordered table-striped" >



            <thead>
              <tr className="bg-secondary text-white">

                <th>S No.</th>
                <th>Product Id</th>
                <th>Product</th>
                <th>Status</th>
                <th>Start Date & Time</th>
              </tr>
            </thead>
            <tbody>

              {surveyList && surveyList.map((dataList, indexList) => (
                <tr key={indexList}>
                  <th scope="row">{indexList + 1}</th>
                  <td>{dataList.Id}</td>
                  <td>{dataList.Product}
                    <span onClick={() => { setShowUpi(false); setSurveyId(null) }} className="float-right read-date"> <i className="fa fa-minus-circle text-info" aria-hidden="true" /></span>
                    <span onClick={() => { upiId(dataList,false);upiIdDownload(dataList,false) }} className="float-right read-date"> <i className="fa fa-plus-circle text-info mr-2" aria-hidden="true" /></span>
                  </td>
                  <td>{dataList.Status}</td>
                  <td>{dataList['Survey Created Time']}</td>
                </tr>
              ))}

            </tbody>

          </table>
        </>


      </div>



      {(showUpi === true || showFastag === true || showRupay === true || showBhim === true) && <div className="container">
        <div className="row">
          <TablePartner onChange={onChange} partnerArr={partnerArr} selectedPartner={selectedPartner} />
          <TableZone onChange={onChange} zoneArr={zoneArr} selectedZone={selectedZone} />
          <TableState onChange={onChange} stateArr={stateArr} selectedState={selectedState} />
          <TableCity onChange={onChange} cityArr={cityArr} selectedCity={selectedCity} />
          <div className="col-lg-3 px-1">
            <DateRangePicker rangeValue={[startDate,endDate]} DateStart={setStartDate} DateEnd={setEndDate} />
          </div>
          <div className="col-lg-3  px-1">
            {selectedPartner.length > 0 || selectedZone.length > 0 || selectedState.length > 0 || selectedCity.length > 0 || startDate !== "" || endDate !== "" ?
            <div><Button className="mt-4 py-2 read-date mr-2" color="secondary" onClick={applyFilterFunc}>Apply</Button>
                <Button className="mt-4 py-2 read-date ml-2" color="info" onClick={resetFilter}>Reset</Button>
              </div> :
              <Button className="mt-4 py-2" disabled >Apply</Button>
            }
          </div>
        </div>
        {loader == true ?
          <Skeleton count={15} /> :
          <AllPartnerCityWiseTableDemo surveyId={surveyId} upiId={upiId} upiIdDownload={upiIdDownload}
            responseDownloadData={responseDownloadData} npsScoreKey={npsScoreKey} npsScoreList={npsScoreList}
            setItemPerPage={setItemPerPage} itemPerPage={itemPerPage} totalItem={totalItem} totalPage={totalPage} page={page} setPage={setPage}
            handlePageChange={handlePageChange} totalData={totalData} firstIndexItem={firstIndexItem} applyFilter={applyFilter}
          />
        }
      </div>
      }


    </div>

  );
}

export default TableCityStateZone;
import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
// import Pagination from "react-js-pagination";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { CSVLink, CSVDownload } from "react-csv";
import Pdf from "react-to-pdf";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import _ from "lodash";
import DateRangePicker from '../../common/dateRangePicker';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PDFExport } from '@progress/kendo-react-pdf';
import SentimentSmile from '../Voc/sentimentSmile';
import { Switch} from 'antd';
import VocChart from '../charts/vocChart';
import QuadrantChart from '../charts/quadrantChart';


const VocReport = (props) => {
  const csvLink = useRef()
  const inputEl = useRef(null);
  const ref = React.createRef();
  const api = new API()
  const Id = props.match.params.id;
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [surveyResponseList, setSurveyResponseList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)
  const [responseDownloadData, setResponseDownloadData] = useState([])
  const [sentimentData, setSentimentData] = useState([])
  const [trendChartData, setTrendChartData] = useState([])
  const [itemPerPage, setItemPerPage] = useState(5)
  const [searchMobile, setSearchMobile] = useState('')
  const [searchPartner, setSearchPartner] = useState('')
  const [sendStatus, setSendStatus] = useState('')
  const [surveyResponse, setSurveyResponse] = useState('')
  const [isAsending, setIsAsending] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [more, setMore] = useState(false);
  const [disable,setDisable] = useState(true)
  const[chartView,setChartView] = useState(false)
  const [mainCat, setMainCat] = useState('')
  const [cat,setCat] = useState(""); 
  const [quadrantData, setQuadrantData] = useState([])
  const [overAllNps, setOverAllNps] = useState("")
  const [overAllImpact, setOverAllImpact] = useState("")
  const [minNps, setMinNps] = useState("")
  const [maxNps, setMaxNps] = useState("")
  const [minImpact, setMinImpact] = useState("")
  const [maxImpact, setMaxImpact] = useState("")


  useEffect(() => {
    ListViewResponse()
    // responseDownload(Id)
    SentimentNpsResponse()
  }, [])

  useEffect(() => {
    ListViewResponse()
    TrendChartResponse()
    SentimentNpsResponse()
    quadrantChartResponse()
    // responseDownload(Id)
  }, [page, itemPerPage, sendStatus, surveyResponse, searchPartner, searchMobile, startDate, endDate])

  const  quadrantChartResponse =async()=>{
           
    let Data={
      start_date:startDate,
      end_date:endDate
    }

    if (Data.start_date==="") {
      delete Data.start_date
    }
    if (Data.end_date==="") {
      delete Data.end_date
    }
        const result = await api.post(config.quadrantChart, Data)
        let responseData = result && result.data
    console.log("responseData==>",responseData);
        setQuadrantData(responseData.data)
        setOverAllNps(responseData.total_nps)
        setOverAllImpact(responseData.total_impact_on_nps)
        setMinImpact(responseData.min_impact)
        setMaxImpact(responseData.max_impact)
        setMinNps(responseData.min_nps)
        setMaxNps(responseData.max_nps)
        setLoader(false)
     } 

  const ListViewResponse = async () => {
    let
      Data = {
        page_no: page, survey_id: Id, item_per_page: itemPerPage,
        send_status: sendStatus, survey_response: surveyResponse, mobile: searchMobile, partner: searchPartner,
        start_date: startDate, end_date: endDate
      }
    if (Data.mobile === "") {
      delete Data.mobile
    }
    if (Data.send_status === "") {
      delete Data.send_status
    }
    if (Data.survey_response === "") {
      delete Data.survey_response
    }
    if (Data.partner === "") {
      delete Data.partner
    }
    if (Data.start_date === "") {
      delete Data.start_date
    }
    if (Data.end_date === "") {
      delete Data.end_date
    }

    const result = await api.post(config.vocReportList, Data)
    let responseData = result && result.data && result.data.finalResult
    let surveyResponseData = result && result.data

    setSurveyResponseList(responseData)
    setTotalItem(surveyResponseData && surveyResponseData.total_item)
    setTotalPage(surveyResponseData && surveyResponseData.total_page)
    setTotalData(surveyResponseData && surveyResponseData.last_index)
    setCurrentDataCount(surveyResponseData && surveyResponseData.current_page)
    setFirstIndexItem(surveyResponseData && surveyResponseData.first_index)
    setLoader(false)


  }

  const handlePageChange = async (event, value) => {
    setPage(value);
    setLoader(false)
  }


  const  SentimentNpsResponse =async()=>{
           
    let Data={
      survey_id: Id,
      start_date:startDate,
      end_date:endDate
    }

    if (Data.start_date==="") {
      delete Data.start_date
    }
    if (Data.end_date==="") {
      delete Data.end_date
    }
        const result = await api.post(config.vocSentimentNps, Data)
        let responseData = result && result.data
    
        setSentimentData(responseData)
        setLoader(false)
     } 

     const  TrendChartResponse =async(mainCat="")=>{
           
      let Data={
        survey_id: Id,
        start_date:startDate,
        end_date:endDate,
        main_cat: mainCat,
      }

      if (Data.start_date==="") {
        delete Data.start_date
      }
      if (Data.end_date==="") {
        delete Data.end_date
      }
      if (Data.main_cat==="") {
        delete Data.main_cat
      }
          const result = await api.post(config.vocTrendChart, Data)
          let responseData = result && result.data
      
          setTrendChartData(responseData)
          setLoader(false)
       } 
      
  const responseDownload = async (Id,done=null) => {

   
    let Datas = {
      survey_id: Id, send_status: sendStatus, survey_response: surveyResponse, mobile: searchMobile, partner: searchPartner,
      start_date: startDate, end_date: endDate
    }
    if (Datas.mobile === "") {
      delete Datas.mobile
    }
    if (Datas.send_status === "") {
      delete Datas.send_status
    }
    if (Datas.survey_response === "") {
      delete Datas.survey_response
    }
    if (Datas.partner === "") {
      delete Datas.partner
    }
    if (Datas.start_date === "") {
      delete Datas.start_date
    }
    if (Datas.end_date === "") {
      delete Datas.end_date
    }
    const result = await api.post(config.reportSurveyResponseDownload, Datas)
    setResponseDownloadData(result.data)
    done && done()
    console.log(result);
    setLoader(false)
    setDisable(false)
  }

  const sortFilter = (sortBy, order) => {
    let tem = _.orderBy(surveyResponseList, [sortBy], [order])
    setSurveyResponseList(tem);
    setIsAsending(prev => !prev)
    setLoader(false)
  };
  const onButtonPdfClick = () => {
    inputEl.current.save();
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
    setChartView(checked)
}
const ResetChart = async(e)=>{
  setCat("")
  TrendChartResponse("")
}

  return (
<>
    <div className="container">
      

    <DateRangePicker  DateStart={setStartDate} DateEnd={setEndDate} />
      <div className="bg-dark card-body py-3  text-center"><h5 className="mb-0 text-white">VOC Report List
      
      <Switch className="float-right" checked={chartView=== true ? true :false} onChange={()=> setChartView(!chartView)} />
      <label className="float-right mr-2">Chart View </label>
      </h5></div>
      {loader == true ?
        <Skeleton count={20} /> :
        <>
          <div className="text-left py-4 d-flex bg-light card-body justify-content-between">
            <div className>
              <label >Rows per page:</label>
              <select onChange={(e) => setItemPerPage(e.target.value)} value={itemPerPage} className="form-control" id="sel1">
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>

            {/* <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate} /> */}
            {/* { disable  ?  <DropdownToggle className="btn white-btn mt-4">
              <DropdownItem>Please Wait For Download</DropdownItem>
            <Skeleton circle={true} height={25} width={25} /> 
              </DropdownToggle> :  <ButtonDropdown  isOpen={more} toggle={() => setMore(!more)}>
              <DropdownToggle className="btn white-btn mt-4">
                <i    className="fa  fa-bars ml-2 btn color-white" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <CSVLink asyncOnClick={true}
                  
                   color="black" data={responseDownloadData}
                    filename={"Survey-Response-List.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink>
                </DropdownItem>

                <DropdownItem>
                  <span onClick={onButtonPdfClick}>Generate Pdf</span>

                </DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
} */}
          </div>
          {/* <PDFExport
            forcePageBreak=".page-break"
            paperSize={'A4'}
            margin="1.5cm"
            scale={0.7}
            fileName={'Survey-Response-List.pdf'}
            ref={inputEl}
          > */}
            <div ref={ref} className="table text-center mb-4">

              <table className="table table-Common table-bordered table-striped" >


                <thead>
                  <tr className="bg-secondary text-white"> 
                    <th>S No.</th>
                    <th className="colspan-2" style={{ cursor: 'pointer' }} onClick={() => sortFilter('Survey', isAsending ? 'desc' : 'asc')}>Survey</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Partner Name', isAsending ? 'desc' : 'asc')} className="table-td">Partner Name</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Main Category', isAsending ? 'desc' : 'asc')} className="table-td">Main Category</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Sub-Category', isAsending ? 'desc' : 'asc')} className="table-td">Sub-Category</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('Total Response', isAsending ? 'desc' : 'asc')} className="table-td">Total Response</th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('nps_score', isAsending ? 'desc' : 'asc')} className="table-td">NPS Score <i className={isAsending ? "fa fa-sort-asc" : "fa fa-sort-desc"} style={{ color: 'white' }} /></th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('promoters', isAsending ? 'desc' : 'asc')} className="table-td">Promoters </th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('passive', isAsending ? 'desc' : 'asc')} className="table-td">Passive </th>
                    <th style={{ cursor: 'pointer' }} onClick={() => sortFilter('detractors', isAsending ? 'desc' : 'asc')} className="table-td">Detractors </th>
                  </tr>
                </thead>

                <tbody>

                
                  {surveyResponseList && surveyResponseList.length === 0 ? 
                  <tr><td className="colspan-10">"No Result"</td></tr>
                  :
                  surveyResponseList && surveyResponseList.map((data, index) => (
                    <tr key={index}>
                      <td className="table-td-1" scope="row">{firstIndexItem + index}</td>
                      <td >{data.survey}</td>
                      <td >{data.partner_name}</td>
                      <td >{data.main_cat}</td>
                      <td >{data.sub_cat}</td>
                      <td >{data.total_response}</td>
                      <td >{data["nps_score"]} <span className="curtom-circle"
                       style={{ backgroundColor: data.color_code }}
                       >
                           </span></td>
                      <td >{data["promoters"]}</td>
                      <td >{data["passive"]}</td>
                      <td >{data["detractors"]}</td>
                    </tr>
                  ))}
                </tbody>



              </table>
              <div className="text-center d-flex justify-content-between align-items-center "  >
                <Pagination className="mb-3" count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="primary" align="center" />
                <h5 className="float-left mb-3">{firstIndexItem} - {totalData} of {totalItem} items</h5>
              </div>


            </div>
          {/* </PDFExport> */}
        </>
      }
    </div >
    {chartView ? <div className="container">
    <div className="bg-dark card-body py-3  text-center">
      <h5 className=" text-white">VOC Category & Sub-category 
    <button className="btn btn-secondary float-right " onClick={ResetChart}>Reset</button>
      </h5>
    </div>
     
        <VocChart Category={get(trendChartData,"categories",[])} setMainCat={setMainCat} 
        mainCat={mainCat} TrendChartResponseFunction={TrendChartResponse}
         DataSeries={get(trendChartData,"series",[])} cat={cat} setCat={setCat}
         TotalPercent={get(trendChartData,"total_percent",[])}
          />
        
        </div>
    : null}
    <SentimentSmile SentimentData={sentimentData}/>
    <div className="container">
    <div className="bg-dark card-body py-3  text-center">
      
      <h5 className=" text-white">Quadrant Wise
    {/* <button className="btn btn-secondary float-right " onClick={ResetChart}>Reset</button> */}
      </h5>
    </div>
<QuadrantChart startDate={startDate} endDate={endDate} overAllImpact={parseFloat(overAllImpact).toFixed(2)}
 overAllNps={parseFloat(overAllNps).toFixed(2)} quadrantData={quadrantData} 
 minImpact={parseFloat(minImpact).toFixed(2)} minNps={parseFloat(minNps).toFixed(2)}
 maxImpact={parseFloat(maxImpact).toFixed(2)} maxNps={parseFloat(maxNps).toFixed(2)}
 />
</div>
    </>
  );
}

export default VocReport;
import React, { useState,useEffect } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get, set } from 'lodash'
import CommentModel from '../../common/commentModel';
Timeline(Highcharts);

const RatingWiseComment = (props) => {

    const api = new API()
    const {Name,} = props
    const [ ratingWiseComments, setRatingWiseComments] = useState([])
    const [open, setOpen] = useState(false);
    const [ratingCommentPopupData, setRatingCommentPopupData] = useState([])


    useEffect(() => {
        ratingWiseComment()
    },[Name])

     // ratingWiseComment//
     const ratingWiseComment = async()=> {
         
        let suggectionAndFeedbackResult = await api.get(config.dashboardRatingWiseComment,Name==='All' ? false:true,{type:Name})
        let suggectionAndFeedbackData = get(suggectionAndFeedbackResult,'data','')
        
        setRatingWiseComments(suggectionAndFeedbackData)
    }
    
    const ratingCommentsPopup = async(Id) => {
        // debugger
        let ratingComment = await api.get(config.dashboardCommentDetailClicked,true,{id:Id})
        let ratingCommentsGet = get(ratingComment, "data","")
        // alert(JSON.stringify(ratingCommentsGet))
        setRatingCommentPopupData(ratingCommentsGet)
        // setOpen(!open)

    }

      const handleModel = async (e) => {
          
        //   console.log(e.point.id)
          const { id } = get(e, "point")
          console.log("id is", id)
          if(!open){
            ratingCommentsPopup(id)
          }
            
          setOpen(!open)
    
      }
      
const options = {
    chart: {
        type: 'scatter',
        scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1
          },
        //   scatter:{
        //     dispatch: {
        //         on:("elementClick", function(e){
        //             alert("clicked")
        //         })
        //     },
        //   },        // zoomType: 'xy'
    },
    // scrollbar: {
    //     barBackgroundColor: 'gray',
    //     barBorderRadius: 7,
    //     barBorderWidth: 0,
    //     buttonBackgroundColor: 'gray',
    //     buttonBorderWidth: 0,
    //     buttonBorderRadius: 7,
    //     trackBackgroundColor: 'none',
    //     trackBorderWidth: 1,
    //     trackBorderRadius: 8,
    //     trackBorderColor: '#CCC'
    // },
    title: {
        text: ''
    },
    credits: {
        enabled: false
    },
    subtitle: {
        useHTML: true,
        text: ''
    },
    yAxis: {
        title: {
            text: ''
        },
        max: 10,
    },
    xAxis: {
        title: {
            text: 'Rating Wise Comments'
        },
        scrollbar: {
            // enabled: true,
          },
          max: 10,
          labels: {
            overflow: 'justify'
        },
    },

    legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 15,
        y: 0,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
        borderWidth: 1
    },
    exporting: {
        enabled: false
      },
    plotOptions: {
        scatter: {
            marker: {
                radius: 5,
                states: {
                    hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                    }
                }
            },
            states: {
                hover: {
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                useHTML: true,
                headerFormat: null,
                pointFormat: '<b>Comments</b>: {point.name}<br><b>Rating</b>: {point.y} '

            },
        },
      
        series: {
            data: ratingWiseComments,
            cursor: 'pointer',
            events: {
                click: function (e) {
                    // alert('clicked');
                    handleModel(e)
                }
            }
        }
    },

    series: ratingWiseComments,
   
            // keys: ['x', 'y', 'name'],
            // color: "#ea6f67",
            // name: [Name].toString(),
            // data: [
        //         [1, 4, 'Requirement for improving in customer service...it takes half an hour to contact customer care center ...'],
        //         [2.3, 6, 'Improve customer service and be ethical, else people will dump bhim'],
        //         [2.9, 2, 'Improve customer service and be ethical, else people will dump bhim'],
        //         [3.5, 3, 'Improve customer service and be ethical, else people will dump bhim'],
        //         [4.5, 1, 'Improve customer service and be ethical, else people will dump bhim']
                
        //     ]
        // }, {
        //     keys: ['x', 'y', 'name'],
        //     color: '#f7b952',
        //     name: "Passive",
        //     data: [
        //         [2.1, 7, 'Improve security'],
        //         [4, 8, 'Cashback offers can be improved'],

        //     ]
        // }, {
        //     keys: ['x', 'y', 'name'],
        //     color: '#52c37b',
        //     name: "Promoter",
        //     data: [
        //         [2.5, 9, 'All service is good and I like it cash back'],
        //         [7, 10, 'Good acceptance  across merchants only if it can learn from bhim  for shopping'],
        //         [3.1, 9, 'Good acceptance  across merchants'],
        //         [4.4, 9, 'All service is good and I like it cash back'],
        //         [5.8, 9, 'All service is good'],
        //         [6.5, 9, 'I like it cash back'],

            // ]
    //     },
        
    // ]
}

    return ( 
        <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        
            
        />
        <div style={{display:"none"}}><CommentModel handleModel={() => setOpen(!open)} commentDetailResultData={ratingCommentPopupData} open={open} />
      </div>
      </div>
     );
}
 
export default RatingWiseComment;